// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import { idField } from '../abstract/StoredEntity';
import type {StoredEntity} from "../abstract/StoredEntity";
import {AscendingSortOrder, Geography} from "../types";
import {constructEndpoints} from "../helpers";

class RewardProgram implements StoredEntity {
    static classInternalName = 'reward_program';

    static fields: EntityFieldCollection = {
        id: idField,
        name: {
            displayName: 'Name',
            recordName: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        type: {
            displayName: 'Type',
            recordName: 'program_type',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Travel',
                    2: 'Cash',
                    3: 'Hybrid'
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        geography: {
            displayName: 'Geography',
            recordName: 'geography',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: Geography,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = {
        fieldName: 'name',
        field: RewardProgram.fields.name,
        order: AscendingSortOrder,
    };

    get id() {
        return this.record[RewardProgram.fields.id.recordName];
    }

    get displayName() {
        return this.record[RewardProgram.fields.name.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('reward-programs');

    constructor(record: any) {
        this.record = record;
    }
}

export default RewardProgram;
