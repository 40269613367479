// @flow

import type { InfoState, InfoAction } from './types';

export function getDefaultInfoState(): InfoState {
    return {
        entity: undefined,
        suggestions: undefined,
    };
}

export default function reduceInfo(state: InfoState, action: InfoAction): InfoState {
    switch (action.type) {
        case 'info/receiveEntity':
            return {
                ...state,
                entity: action.entity,
                suggestions: action.suggestions,
            };

        case 'info/cleanUp':
            return getDefaultInfoState();

        default:
            return state;
    }
}
