// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';
import LoadingContainer from '../basicElements/LoadingContainer';
import StyleClasses from '../../styles/StyleClasses';
import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type { Suggestions } from '../../entities/types';
import { cleanUpInfo, fetchEntity } from '../../store/info/actions';
import type { AppState } from '../../store/app/types';
import type { TopLevelAction} from '../types';
import { convertFieldValuesToComponents } from '../helpers';
import { getRandomString, zip } from '../utils';

//
// PROPS
//

type PropsType = {
    // MODE
    model: $Shape<StoredEntity>,
    modeName: string,
    actions: [TopLevelAction],
    titleGetter: (entity: StoredEntity) => string,
    // INJECTED FUNCTIONS
    fetchEntity: (model: $Shape<StoredEntity>, modeName: string, id: string) => void,
    cleanUpInfo: (modeName: string) => void,
    // STATE
    entity: StoredEntity, // state
    suggestions: Suggestions, // state
    // ROUTER
    history: any,
    match: any,
};

//
// CLASS
//

class InfoView extends Component<PropsType, {}> {
    //
    // INIT
    //

    constructor(props) {
        super(props);
        this.fields = this.props.model.fields;
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount(): void {
        const entityId = this.props.match.params.id;
        this.props.fetchEntity(this.props.model, this.props.modeName, entityId);
    }

    componentWillUnmount(): void {
        // Clean up the state
        this.props.cleanUpInfo(this.props.modeName);
    }

    //
    // RENDER
    //

    render() {
        // If any of the network resources are not here, display a loading indicator
        if (this.props.suggestions === undefined || this.props.entity === undefined) {
            return <LoadingContainer />;
        }

        // Get the value components for each field
        const values = convertFieldValuesToComponents(this.fields, this.props.entity, this.props.suggestions, true);

        // Make rows which look like `field name: value`
        const rows = zip(Object.values(this.fields), values).map(([field, value]) => (
            <tr key={getRandomString()}>
                <td className="w-25">{field.displayName}</td>
                <td>{value}</td>
            </tr>
        ));

        // Put them in a table
        const bodyComponent = (
            <Table>
                <thead>
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        );

        // Construct the action buttons at the top of the component
        const actions = this.props.actions.map((action) => (
            <Button
                className={StyleClasses.spacedElement}
                onClick={() => action.handler(this.props.history)}
                key={getRandomString()}
            >
                {action.text}
            </Button>
        ));

        // Render everything with a nice header
        return (
            <div>
                {/* Header */}
                <h2 className={StyleClasses.spacedElement}>{this.props.titleGetter(this.props.entity)}</h2>
                {/* Top-level actions */}
                {actions}
                {/* The info itself */}
                {bodyComponent}
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    const appState: AppState = state.app;
    return appState.modes.availableModes[appState.modes.currentModeName];
}

export default withRouter(connect(mapStateToProps, { fetchEntity, cleanUpInfo })(InfoView));
