// @flow

import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

type PropsType = {
    activePage: Number,
    totalCount: Number,
    onChange: Function,
};

export default class ListPagination extends Component<PropsType> {
    render() {
        return (
            <Pagination
                activePage={this.props.activePage}
                itemsCountPerPage={15}
                totalItemsCount={this.props.totalCount}
                pageRangeDisplayed={5}
                onChange={(newPage) => {
                    // No useless reload
                    if (newPage !== this.props.activePage) {
                        this.props.onChange(newPage);
                    }
                }}
                innerClass="pagination justify-content-center"
                itemClass="page-item"
                linkClass="page-link text-secondary"
                activeLinkClass="page-link text-light bg-secondary border-secondary"
            />
        );
    }
}
