// @flow

import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

type PropTypes = {
    id: string,
    value: string | number,
    displayName: string,
    inputType: 'text' | 'email' | 'number' | 'date',
    onChange: (string, string | number) => void,
};

class ControlledInputField extends Component<PropTypes, {}> {
    onChange(event) {
        this.props.onChange(event.target.id, event.target.value);
    }

    render() {
        return (
            <FormGroup>
                <Label for={this.props.id}>{this.props.displayName}</Label>
                <Input
                    type={this.props.inputType}
                    id={this.props.id}
                    value={this.props.value}
                    onChange={(event) => this.onChange(event)}
                />
            </FormGroup>
        );
    }
}

export default ControlledInputField;
