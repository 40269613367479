// @flow

import React, { Component } from 'react';
import StyleClasses from '../../styles/StyleClasses';
import { getActionButton } from '../utils';

type PropsType = {
    child: Component,
};

type StateType = {
    isOpen: boolean,
};

export default class ShowHideComponent extends Component<PropsType, StateType> {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
    }

    render() {
        const button = getActionButton(this.state.isOpen ? 'Hide' : 'Show', () =>
            this.setState({ isOpen: !this.state.isOpen }),
        );

        const table = this.state.isOpen ? this.props.child : null;

        return (
            <div>
                <div className={`${StyleClasses.centeredElement} ${StyleClasses.spacedElement}`}>{button}</div>
                {table}
            </div>
        );
    }
}
