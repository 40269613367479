// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';
import Admin from './Admin';
import Policy from './Policy';
import { DescendingSortOrder } from '../types';

class AdminRT implements StoredEntity {
    static classInternalName = 'admin_rt';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        expiration: {
            displayName: 'Expiration',
            recordName: 'expiration',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        issuerId: {
            displayName: 'Issuer',
            recordName: 'issuer_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Admin,
                    maxValues: 1,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: false,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        userId: {
            displayName: 'User',
            recordName: 'user_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Admin,
                    maxValues: 1,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: false,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        grantsSuper: {
            displayName: 'Grants Super',
            recordName: 'grants_super',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        policyId: {
            displayName: 'Policy',
            recordName: 'policy_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    maxValues: 1,
                    model: Policy,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = {
        field: AdminRT.fields.expiration,
        fieldName: 'expiration',
        order: DescendingSortOrder,
    };

    get id() {
        return this.record[AdminRT.fields.id.recordName];
    }

    get displayName() {
        return this.record[AdminRT.fields.id.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('admin-rts');

    constructor(record: any) {
        this.record = record;
    }
}

export default AdminRT;
