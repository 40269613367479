// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';

class RedditCheck implements StoredEntity {
    static classInternalName = 'reddit_check';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        ref: {
            displayName: 'Reference',
            recordName: 'ref',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: false,
            displayOnMain: true,
        },
        redditId: {
            displayName: 'Reddit Handle',
            recordName: 'reddit_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: true,
            displayOnMain: true,
        },
        redditVerified: {
            displayName: 'Reddit Verified',
            recordName: 'reddit_verified',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: false,
            displayOnMain: true,
        },
        email: {
            displayName: 'Email',
            recordName: 'email',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: true,
            displayOnMain: true,
        },
        emailOtp: {
            displayName: 'Email OTP',
            recordName: 'email_otp',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: true,
            displayOnMain: true,
        },
        emailVerified: {
            displayName: 'Email Verified',
            recordName: 'email_verified',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: true,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[RedditCheck.fields.id.recordName];
    }

    get displayName() {
        return this.record[RedditCheck.fields.id.recordName]
    }

    static endpoints: Endpoints = constructEndpoints('reddit-checks');

    constructor(record: any) {
        this.record = record;
    }
}

export default RedditCheck;
