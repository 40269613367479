// @flow

import AuthToken from '../entities/AuthToken';
import type {EntityFieldCollection, Suggestions} from '../entities/types';
import { setErrorAction } from './errors/actions';
import type { StoredEntity } from '../entities/abstract/StoredEntity';

//
// TYPES
//

export type RequestStatus = 'pending' | 'ok' | 'partial' | 'fail' | undefined;

//
// HELPERS
//

export function encodeQueryData(data) {
    const ret = [];
    for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    return ret.join('&');
}

export function buildUrl(path: string) {
    return `${process.env.REACT_APP_BACKOFFICE_ROOT || '/api/backoffice'}/${path}`;
}

//
// AUTH
//

export function getBearerAuthorizationHeader() {
    /*
     * Construct an Authorization header with a Bearer scheme and the token from local storage.
     */
    return {
        Authorization: `Bearer ${AuthToken.get()}`,
    };
}

export function constructBasicAuthHeader(email, password) {
    /*
     * Construct an Authorization header with a Basic scheme and b64-encoded email and password.
     */
    return {
        Authorization: `Basic ${btoa(`${email}:${password}`)}`,
    };
}

//
// PRE-PROCESSING
//

export function getJsonContentTypeHeader() {
    return {
        'Content-Type': 'application/json',
    };
}

// eslint-disable-next-line no-unused-vars
export function getJsonRequestBody(json) {
    return JSON.stringify(json);
}

export function getFileRequestBody(file) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
}

//
// POST-PROCESSING
//

export function processFetchErrors(promise): Promise {
    return promise.then((response) => {
        if (response.ok) {
            return response.json();
        }
        return response
            .json()
            .catch(() => {
                throw new Error(`Request failed with status ${response.status}. No message was available.`);
            })
            .then((json) => {
                throw new Error(`${response.status}: ${json.message}`);
            });
    });
}

//
// HTTP REQUEST WRAPPERS
//

export function jsonGetRequest(url, processErrors = true): Promise {
    const promise = fetch(buildUrl(url), {
        method: 'GET',
        headers: getBearerAuthorizationHeader(),
    });
    return processErrors ? processFetchErrors(promise) : promise;
}

export function jsonPatchRequest(url, body, processErrors = true): Promise {
    const promise = fetch(buildUrl(url), {
        method: 'PATCH',
        headers: { ...getBearerAuthorizationHeader(), ...getJsonContentTypeHeader() },
        body,
    });
    return processErrors ? processFetchErrors(promise) : promise;
}

export function filePutRequest(url, body, processErrors = true): Promise {
    const promise = fetch(buildUrl(url), {
        method: 'PUT',
        headers: getBearerAuthorizationHeader(),
        body,
    });
    return processErrors ? processFetchErrors(promise) : promise;
}

export function deleteRequest(url, processErrors = true): Promise {
    const promise = fetch(buildUrl(url), {
        method: 'DELETE',
        headers: { ...getBearerAuthorizationHeader() },
    });
    return processErrors ? processFetchErrors(promise) : promise;
}

export function jsonPostRequest(url, body, processErrors = true): Promise {
    const promise = fetch(buildUrl(url), {
        method: 'POST',
        headers: { ...getBearerAuthorizationHeader(), ...getJsonContentTypeHeader() },
        body,
    });
    return processErrors ? processFetchErrors(promise) : promise;
}

//
// COMMON REQUESTS
//

export function genericFetchSuggestions(
    dispatch: any,
    modeName: string,
    fields: EntityFieldCollection,
    receiveSuggestions: (string, Map, [[StoredEntity]]) => any,
) {
    const promises = [];

    const mappedFields = new Map(Object.entries(fields));

    mappedFields.forEach((field) => {
        promises.push(jsonGetRequest(field.dataType.extraData.model.endpoints.suggestions()));
    });

    Promise.all(promises)
        .then((values) => dispatch(receiveSuggestions(modeName, mappedFields, values)))
        .catch((error) => dispatch(setErrorAction(error.toString())));
}

export function genericProcessSuggestions(mappedFields: Map, values: [[StoredEntity]]): Suggestions {
    const suggestions = {};

    // For every field, get the corresponding suggestions by index
    // (we can do that because Map is ordered and it's used in fetchSuggestions)
    // Then deserialize every record
    let idx = 0;
    mappedFields.forEach((field) => {
        // eslint-disable-next-line new-cap
        const entities = values[idx].suggestions[field.dataType.extraData.model.classInternalName]
        if (field.dataType.primitive === 'ref') {
            suggestions[field.dataType.extraData.model.classInternalName] = entities;
        }
        idx += 1;
    });

    return suggestions;
}
