// @flow

import React, { Component } from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { Button } from 'reactstrap';
import AuthToken from './entities/AuthToken';

import LoginForm from './ui/accessControl/LoginForm';
import Admin from './entities/models/Admin';
import type { AppInitializationStatus } from './store/app/types';
import { fetchGovernor, fetchPolicy, setInitializationStatus } from './store/app/actions';
import { getAllModes } from './ui/modes';
import LoadingContainer from './ui/basicElements/LoadingContainer';
import { addMode, setRegistry, switchMode } from './store/modes/actions';
import GenericPage from './ui/basicElements/GenericPage';
import type { PanelRegistry } from './ui/registry';
import { PolicyBasedRegistry, SuperRegistry } from './ui/registry';
import ErrorAlert from './ui/basicElements/errors/ErrorAlert';
import StyleClasses from './styles/StyleClasses';
import ErrorWrapper from './ui/basicElements/errors/ErrorWrapper';
import type { ModeCollection } from './ui/modes';
import Policy from './entities/models/Policy';

//
// PROPS
//

type PropsType = {
    // STATE
    governor: Admin | undefined,
    policy: Policy | undefined,
    initializationStatus: AppInitializationStatus,
    // ROUTER
    history: any,
    currentModeName: string,
    // INJECTED FUNCTIONS
    fetchGovernor: () => void,
    fetchPolicy: () => void,
    setInitializationStatus: (status: AppInitializationStatus) => void,
    addMode: (modeName: string) => void,
    switchMode: (modeName: string) => void,
    setRegistry: (registry: PanelRegistry) => void,
};

//
// STATE
//

type StateType = {
    errorAlert: Component,
    errorTimer: Number,
};

//
// CLASS
//

class App extends Component<PropsType, StateType> {
    token: string;

    registry: PanelRegistry;

    modes: ModeCollection;

    constructor(props) {
        super(props);

        // Error stuff

        this.state = {
            errorAlert: undefined,
            errorTimer: undefined,
        };

        this.onError = this.onError.bind(this);
        this.unsetError = this.unsetError.bind(this);

        // Token

        // Not state because doesn't change within the lifecycle
        // When it gets reset the page resets too
        this.token = AuthToken.get();

        if (this.token) {
            this.props.fetchGovernor();
            this.props.setInitializationStatus('pending');
        } else {
            this.props.setInitializationStatus('authNeeded');
        }
    }

    componentDidUpdate(): * {
        const { governor } = this.props;

        if (this.props.initializationStatus === 'ok') {
            return;
        }

        if (governor !== undefined) {
            const isSuper = governor.record[Admin.fields.isSuper.recordName];

            if (isSuper) {
                this.registry = new SuperRegistry();
            } else {
                const { policy } = this.props;

                if (policy !== undefined) {
                    this.registry = new PolicyBasedRegistry(policy);
                } else {
                    this.props.fetchPolicy(governor.record.policy.id);
                    return;
                }
            }

            this.props.setRegistry(this.registry);
            this.modes = getAllModes(this.registry);
            this.reduxAddModes(this.modes);
            this.props.setInitializationStatus('ok');
        }
    }

    reduxAddModes() {
        Object.entries(this.modes).forEach(([name]) => {
            this.props.addMode(name);
        });
    }

    onError(message: string) {
        if (this.state.errorTimer) {
            clearTimeout(this.state.errorTimer);
        }

        this.setState({
            errorAlert: <ErrorAlert errorString={message} />,
            errorTimer: setTimeout(() => this.unsetError(), 5000),
        });
    }

    unsetError() {
        this.setState({
            errorAlert: undefined,
            errorTimer: undefined,
        });
    }

    governorContent() {
        const routes = Object.entries(this.modes).map(([name, mode]) => {
            return (
                <Route
                    exact
                    key={name}
                    path={mode.routePath}
                    component={() => {
                        if (name !== this.props.currentModeName) {
                            this.props.switchMode(name);

                            // When the page first loads, the current mode is undefined, but the
                            // router reacts to the URL and renders the component. This results in
                            // a double rendering after correctly defining the mode. Therefore,
                            // we wait until we switch the mode, and only then we render the proper component.
                            return (
                                <GenericPage
                                    currentModeName={this.props.currentModeName}
                                    modes={this.modes}
                                    component={<LoadingContainer />}
                                />
                            );
                        }
                        return (
                            <GenericPage
                                currentModeName={this.props.currentModeName}
                                modes={this.modes}
                                component={mode.component}
                            />
                        );
                    }}
                />
            );
        });

        routes.push(
            <Route
                exact
                key="home"
                component={() => {
                    return (
                        <GenericPage
                            currentModeName={undefined}
                            modes={this.modes}
                            component={<h1 className={StyleClasses.spacedElement}>Welcome to Churning Canada Admin!</h1>}
                        />
                    );
                }}
            />,
        );

        return (
            <Router>
                <Switch>{routes}</Switch>
            </Router>
        );
    }

    accessControlContent = () => (
        <Router>
            <Switch>
                <Route component={() => <LoginForm />} />
            </Switch>
        </Router>
    );

    pendingContent = () => (
        <ErrorWrapper>
            <div className={StyleClasses.centeredElement}>
                <LoadingContainer />
                <Button
                    className={StyleClasses.lotSpacedElement}
                    onClick={() => {
                        AuthToken.unset();
                        window.location.replace('/');
                    }}
                >
                    Logout
                </Button>
            </div>
        </ErrorWrapper>
    );

    render() {
        switch (this.props.initializationStatus) {
            case 'authNeeded':
                return this.accessControlContent();
            case 'pending':
                return this.pendingContent();
            case 'ok':
                return this.governorContent();
            default:
                return null;
        }
    }
}

function mapStateToProps(state) {
    const { governor, policy, initializationStatus } = state.app;

    return {
        governor,
        policy,
        initializationStatus,
        currentModeName: state.app.modes.currentModeName,
    };
}

export default connect(mapStateToProps, {
    fetchGovernor,
    fetchPolicy,
    setInitializationStatus,
    addMode,
    switchMode,
    setRegistry,
})(App);
