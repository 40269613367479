// @flow

import type { RequestEdit, ReceiveEditStatus, CleanUpEdit, ReceiveEntity } from './types';

import {
    filePutRequest,
    getFileRequestBody,
    getJsonRequestBody,
    jsonGetRequest,
    jsonPatchRequest,
} from '../requests';
import { setErrorAction } from '../errors/actions';
import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type { EntityFieldCollection, Suggestions } from '../../entities/types';
import type { RequestStatus } from '../requests';
import type { InternalFormData } from '../../ui/types';

function receiveEntity(
    model: $Shape<StoredEntity>,
    modeName: string,
    response: { entity: Object, suggestions: Suggestions }
): ReceiveEntity {
    return {
        type: 'edit/receiveEntity',
        modeName,
        entity: new model(response.entity),
        suggestions: response.suggestions
    };
}

export function fetchEntity(model: $Shape<StoredEntity>, modeName: string, id: string) {
    return (dispatch) => {
        jsonGetRequest(model.endpoints.info(id))
            .then((value) => dispatch(receiveEntity(model, modeName, value)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

function requestEdit(modeName: string): RequestEdit {
    return {
        type: 'edit/request',
        modeName,
    };
}

function receiveEditStatus(modeName: string, status: RequestStatus): ReceiveEditStatus {
    return {
        type: 'edit/receiveStatus',
        modeName,
        status,
    };
}

export function edit(
    model: $Shape<StoredEntity>,
    modeName: string,
    fields: EntityFieldCollection,
    fileFields: EntityFieldCollection,
    entity: StoredEntity,
    data: InternalFormData,
) {
    return (dispatch) => {
        dispatch(requestEdit(modeName));

        const body = {};

        Object.entries(fields).forEach(([name, field]) => {
            let value;

            if (field.isOptional && !data[name]) {
                value = null;
            } else {
                switch (field.dataType.primitive) {
                    case 'str':
                    case 'bool':
                        value = data[name];
                        break;
                    case 'enum': // TODO when becomes segmented control
                    case 'num':
                    case 'ts': // TODO when becomes date
                        value = Number(data[name]);
                        break;
                    case 'ref': {
                        if (data[name].length === 0) {
                            value = field.dataType.isArray ? [] : null;
                        } else {
                            const ids = data[name].map((e) => e.id);
                            value = field.dataType.isArray ? ids : ids[0];
                        }
                        break;
                    }
                    case 'simple-entity': {
                        try {
                            value = JSON.parse(data[name]);
                        } catch (e) {
                            dispatch(setErrorAction(`Invalid JSON in field ${field.name}`));
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            // Comparing strings to account for arrays. This is OK since this function never has incoming objects.
            if (value !== undefined && JSON.stringify(value) !== JSON.stringify(entity.record[field.recordName])) {
                body[field.recordName] = value;
            }
        });

        const promises = [jsonPatchRequest(model.endpoints.info(entity.id), getJsonRequestBody(body))];

        Object.entries(fileFields).forEach(([name, field]) => {
            const value = data[name];
            if (value != null) {
                promises.push(
                    filePutRequest(model.endpoints.file(entity.id, field.fileName), getFileRequestBody(value)),
                );
            }
        });

        Promise.all(promises)
            .then(() => dispatch(receiveEditStatus(modeName, 'ok')))
            .catch((error) => {
                dispatch(setErrorAction(error.toString()));
                dispatch(receiveEditStatus(modeName, 'error'));
            });
    };
}

export function cleanUpEdit(modeName: string): CleanUpEdit {
    return {
        type: 'edit/cleanUp',
        modeName,
    };
}
