// @flow

import React, { Component } from 'react';
import { Form, Button, Container } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import StyleClasses from '../../styles/StyleClasses';
import LoadingContainer from '../basicElements/LoadingContainer';
import ControlledInputField from '../basicElements/inputFields/ControlledInputField';
import TypeaheadInputField from '../basicElements/inputFields/TypeaheadInputField';
import { RequestStatus } from '../../store/requests';

import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type {EntityFieldCollection, Suggestions} from '../../entities/types';
import { getDefaultValueForField } from '../helpers';
import BooleanControlledInputField from '../basicElements/inputFields/BooleanControlledInputField';
import type { AppState } from '../../store/app/types';
import type { InternalFormData} from '../types';
import { fetchEntity, edit, cleanUpEdit } from '../../store/edit/actions';
import FileInputField from '../basicElements/inputFields/FileInputField';

//
// PROPS
//

type PropsType = {
    // MODE
    model: $Shape<StoredEntity>,
    modeName: string,
    titleGetter: (entity: StoredEntity) => string,
    // INJECTED FUNCTIONS
    fetchEntity: (model: $Shape<StoredEntity>, modeName: string, id: string) => void,
    edit: (
        model: $Shape<StoredEntity>,
        modeName: string,
        fields: EntityFieldCollection,
        fileFields: EntityFieldCollection,
        entity: StoredEntity,
        data: InternalFormData,
    ) => void,
    onSuccess: (history: any) => void,
    cleanUpEdit: (modeName: string) => void,
    // STATE
    entity: StoredEntity,
    suggestions: Suggestions,
    editStatus: RequestStatus,
    // ROUTER
    history: any,
    match: any,
};

//
// CLASS
//

class EditForm extends Component<PropsType, {}> {
    fields: EntityFieldCollection;

    constructor(props) {
        super(props);
        this.fields = {};
        this.fileFields = {};
        this.state = {};

        Object.entries(this.props.model.fields).forEach(([name, field]) => {
            if (field.canEdit) {
                this.fields[name] = field;
                this.state[name] = getDefaultValueForField(field);
            }
        });

        Object.entries(this.props.model.fileFields).forEach(([name, field]) => {
            this.fileFields[name] = field;
            this.state[name] = null;
        });
    }

    changeFormState(id, value) {
        this.setState({
            [id]: value,
        });
    }

    submit(event) {
        event.preventDefault();
        this.props.edit(
            this.props.model,
            this.props.modeName,
            this.fields,
            this.fileFields,
            this.props.entity,
            this.state,
        );
    }

    componentDidMount() {
        const entityId = this.props.match.params.id;
        this.props.fetchEntity(this.props.model, this.props.modeName, entityId);
    }

    componentDidUpdate(prevProps: PropsType): * {
        const e = this.props.entity;
        const s = this.props.suggestions;
        if (e && s && (!prevProps.entity || !prevProps.suggestions)) {
            // Set the fields to their initial values
            const toSet = {};

            Object.entries(this.props.model.fields).forEach(([name, field]) => {
                if (field.canEdit) {
                    switch (field.dataType.primitive) {
                        case 'ref': {
                            const sName = field.dataType.extraData.model.classInternalName;
                            if (field.dataType.isArray) {
                                toSet[name] = s[sName].filter((se) => e.record[field.recordName].includes(se.id));
                            } else {
                                toSet[name] = s[sName].filter((se) => se.id === e.record[field.recordName]);
                            }
                            break;
                        }
                        case 'simple-entity':
                            toSet[name] = JSON.stringify(e.record[field.recordName]);
                            break;
                        default:
                            if (field.isOptional && e.record[field.recordName] === null)
                                toSet[name] = '';
                            else
                                toSet[name] = e.record[field.recordName];
                    }
                }
            });

            this.setState(toSet);
        } else {
            // It's impossible to receive both the entity and the request status at the same time, so this is inside else
            const rqS = this.props.editStatus;
            if (rqS === 'ok') {
                this.props.onSuccess(this.props.history);
            }
        }
    }

    componentWillUnmount(): void {
        this.props.cleanUpEdit(this.props.modeName);
    }

    getControlsForFields(): [Component] {
        const fields = [];

        Object.entries(this.fields).forEach(([name, field]) => {
            let control;

            // TODO: Handle plural values
            switch (field.dataType.primitive) {
                case 'str':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="text"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'simple-entity':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="textarea"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'num':
                case 'enum': // TODO: Make it a segmented control
                case 'ts': // TODO: Make it a date picker
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="number"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'ref':
                    control = (
                        <TypeaheadInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            maxValues={field.dataType.extraData.maxValues}
                            initialValue={this.state[name]}
                            options={this.props.suggestions[field.dataType.extraData.model.classInternalName]}
                            onChange={(id, values) => this.changeFormState(id, values)}
                        />
                    );
                    break;
                case 'bool':
                    control = (
                        <BooleanControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                default:
                    break;
            }

            fields.push(control);
        });

        return fields;
    }

    getControlsForFileFields(): [Component] {
        const fileFields = [];
        Object.entries(this.fileFields).forEach(([name, field]) => {
            const control = (
                <FileInputField
                    id={name}
                    key={name}
                    displayName={field.displayName}
                    onFileUpdated={(id, value) => this.changeFormState(id, value)}
                />
            );
            fileFields.push(control);
        });
        return fileFields;
    }

    render() {
        if (
            this.props.entity === undefined ||
            this.props.suggestions === undefined ||
            this.props.editStatus === 'pending'
        ) {
            return <LoadingContainer />;
        }

        return (
            <div>
                <h2 className={StyleClasses.spacedElement}>{this.props.titleGetter(this.props.entity)}</h2>
                <Container fluid>
                    <Form onSubmit={(event) => this.submit(event)}>
                        {this.getControlsForFields()}
                        {this.getControlsForFileFields()}
                        <Button className={StyleClasses.bottomSpacedElement}>Submit</Button>
                    </Form>
                </Container>
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    const appState: AppState = state.app;
    return appState.modes.availableModes[appState.modes.currentModeName];
}

export default withRouter(
    connect(mapStateToProps, {
        fetchEntity,
        edit,
        cleanUpEdit,
    })(EditForm),
);
