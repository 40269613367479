// @flow

import type { ReceiveSuggestions, RequestCreate, ReceiveCreateStatus, CleanUpCreate } from './types';

import {
    filePutRequest,
    genericFetchSuggestions,
    genericProcessSuggestions,
    getFileRequestBody,
    getJsonRequestBody,
    jsonPostRequest,
} from '../requests';
import { setErrorAction } from '../errors/actions';
import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type { EntityFieldCollection } from '../../entities/types';
import type { RequestStatus } from '../requests';
import type { InternalFormData } from '../../ui/types';

function receiveSuggestions(modeName: string, mappedFields: Map, values: [[StoredEntity]]): ReceiveSuggestions {
    return {
        type: 'create/receiveSuggestions',
        modeName,
        suggestions: genericProcessSuggestions(mappedFields, values),
    };
}

export function fetchSuggestions(modeName: string, fields: EntityFieldCollection) {
    return (dispatch) => {
        genericFetchSuggestions(dispatch, modeName, fields, receiveSuggestions);
    };
}

function requestCreate(modeName: string): RequestCreate {
    return {
        type: 'create/request',
        modeName,
    };
}

function receiveCreateStatus(modeName: string, status: RequestStatus): ReceiveCreateStatus {
    return {
        type: 'create/receiveStatus',
        modeName,
        status,
    };
}

function pushFiles(
    model: $Shape<StoredEntity>,
    modeName: string,
    entityId: string,
    fileFields: EntityFieldCollection,
    data: InternalFormData,
) {
    return (dispatch) => {
        const promises = [];

        Object.entries(fileFields).forEach(([name, field]) => {
            const value = data[name];
            if (value != null) {
                promises.push(
                    filePutRequest(model.endpoints.file(entityId, field.fileName), getFileRequestBody(value)),
                );
            }
        });

        Promise.all(promises)
            .then(() => dispatch(receiveCreateStatus(modeName, 'ok')))
            .catch((error) => {
                dispatch(setErrorAction(error.toString()));
                // Partial because entity is created, only files are not pushed
                dispatch(receiveCreateStatus(modeName, 'partial'));
            });
    };
}

export function create(
    model: $Shape<StoredEntity>,
    modeName: string,
    fields: EntityFieldCollection,
    fileFields: EntityFieldCollection,
    data: InternalFormData,
) {
    return (dispatch) => {
        dispatch(requestCreate(modeName));

        const body = {};

        Object.entries(fields).forEach(([name, field]) => {
            let value;

            if (field.isOptional && [undefined, null, ''].includes(data[name])) {
                value = null;
            } else {
                switch (field.dataType.primitive) {
                    case 'str':
                    case 'bool':
                        value = data[name];
                        break;
                    case 'enum': // TODO when becomes segmented control
                    case 'num':
                    case 'ts': // TODO when becomes date
                        value = Number(data[name]);
                        break;
                    case 'ref': {
                        const ids = data[name].map((e) => e.id);
                        value = field.dataType.isArray ? ids : ids[0];
                        break;
                    }
                    case 'simple-entity': {
                        try {
                            value = JSON.parse(data[name]);
                        } catch (e) {
                            dispatch(setErrorAction(`Invalid JSON in field ${field.name}`));
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            body[field.recordName] = value;
        });

        jsonPostRequest(model.endpoints.list(), getJsonRequestBody(body))
            .then((result) => {
                const entityId = new model(result).id;
                if (fileFields) {
                    return dispatch(pushFiles(model, modeName, entityId, fileFields, data));
                }
                dispatch(receiveCreateStatus(modeName, 'ok'));
            })
            .catch((error) => {
                dispatch(setErrorAction(error.toString()));
                dispatch(receiveCreateStatus(modeName, 'error'));
            });
    };
}

export function cleanUpCreate(modeName: string): CleanUpCreate {
    return {
        type: 'create/cleanUp',
        modeName,
    };
}
