// @flow

import { jsonGetRequest } from '../requests';
import { setErrorAction } from '../errors/actions';
import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type {Suggestions} from '../../entities/types';
import type { CleanUpInfo, ReceiveEntity } from './types';

function receiveEntity(
    model: $Shape<StoredEntity>,
    modeName: string,
    response: { entity: Object, suggestions: Suggestions }
): ReceiveEntity {
    return {
        type: 'info/receiveEntity',
        modeName,
        entity: new model(response.entity),
        suggestions: response.suggestions
    };
}

export function fetchEntity(model: $Shape<StoredEntity>, modeName: string, id: string) {
    return (dispatch) => {
        jsonGetRequest(model.endpoints.info(id))
            .then((value) => dispatch(receiveEntity(model, modeName, value)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

export function cleanUpInfo(modeName: string): CleanUpInfo {
    return {
        type: 'info/cleanUp',
        modeName,
    };
}
