// @flow

import { createdAtField, getCreatedAtSort, idField, StoredEntity } from '../abstract/StoredEntity';
import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import PolicyEntityAction from './PolicyEntityAction';
import { constructEndpoints } from '../helpers';

class Policy implements StoredEntity {
    static classInternalName = 'policy';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        entityActions: {
            displayName: 'Entity Actions',
            recordName: 'entity_actions',
            dataType: {
                primitive: 'simple-entity',
                isArray: true,
                extraData: {
                    model: PolicyEntityAction,
                },
            },
            supportsQuery: false,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        permissionActions: {
            displayName: 'Permission Actions',
            recordName: 'permission_actions',
            dataType: {
                primitive: 'simple-entity',
                isArray: true,
                extraData: null,
            },
            supportsQuery: false,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        description: {
            displayName: 'Description',
            recordName: 'description',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        }
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[Policy.fields.id.recordName];
    }

    get displayName() {
        return this.record[Policy.fields.id.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('policies');

    constructor(record: any) {
        this.record = record;
    }
}

export default Policy;
