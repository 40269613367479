// @flow

import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Button, Table} from 'reactstrap';
import LoadingContainer from '../basicElements/LoadingContainer';
import Row from './ListRow';
import {getDefaultValueForField} from '../helpers';
import ListPagination from './ListPagination';
import StyleClasses from '../../styles/StyleClasses';
import type {StoredEntity} from '../../entities/abstract/StoredEntity';
import type {
    FilterMode,
    PaginationParams,
    QueryList,
    QueryParams,
    SortOrder,
    SortParams,
    StoredEntityField, Suggestions,
    ValueToDisplayNameMap,
} from '../../entities/types';
import {cleanUpList, deleteEntity, fetchList} from '../../store/list/actions';
import type {AppState} from '../../store/app/types';
import type {RowAction, TopLevelAction} from '../types';
import ControlledInputField from '../basicElements/inputFields/ControlledInputField';
import TypeaheadInputField from '../basicElements/inputFields/TypeaheadInputField';
import SegmentedControl from '../basicElements/inputFields/SegmentedControl';
import {AscendingSortOrder, DescendingSortOrder} from '../../entities/types';
import {booleanVtDM, getRandomString} from '../utils';

//
// PROPS
//

type PropsType = {
    // MODE
    model: $Shape<StoredEntity>,
    modeName: string,
    titleGetter: () => string,
    listActions: [TopLevelAction],
    rowActions: [RowAction],
    canDelete: boolean,
    // INJECTED FUNCTIONS
    fetchList: (
        model: StoredEntity,
        modeName: string,
        queryParams: QueryParams,
        sortParams: ?SortParams,
        paginationParams: PaginationParams,
    ) => void,
    cleanUpList: (modeName: string) => void,
    deleteEntity: (id: string) => void,
    // STATE
    entityList: [StoredEntity],
    entityCount: number,
    currentPage: number,
    suggestions: Suggestions,
    isDirty: boolean,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {
    queryMenuIsOpen: boolean,
    queries: QueryList,
    sort: SortParams,
};

//
// FILTER
//

type FilterControl = {
    name: string,
    fieldComponent: Component,
    modesComponent: Component,
};

// String search modes are regex or exact match
const stringModeVtDM: ValueToDisplayNameMap = {
    regex: 'Regex',
    eq: 'Exact match',
};

// Number and, by coercion, date search modes are < (before), > (after), or = (exact)
const numberDateModeVtDM: ValueToDisplayNameMap = {
    lt: '<',
    gt: '>',
    eq: '=',
};

const sortOrderVtDM: ValueToDisplayNameMap = {
    [AscendingSortOrder]: 'Ascending',
    [DescendingSortOrder]: 'Descending',
};

// Default the modes for filters that have modes to exact match
function getDefaultModeForField(field: StoredEntityField): FilterMode {
    switch (field.dataType.primitive) {
        case 'str':
        case 'num':
        case 'ts':
        case 'bool':
        case 'enum':
            return 'eq';
        case 'ref':
            return 'in';
        default:
            return null;
    }
}

//
// CLASS
//

class ListView extends Component<PropsType, StateType> {
    //
    // INIT
    //

    constructor(props) {
        super(props);

        //
        // SETUP QUERY & SORTING
        //

        // The list of fields to display in the list/rows
        this.fields = {};

        // A helper collection of all queryable fields
        this.filterFields = {};
        this.sortFields = {};

        // Default state
        this.state = {
            queryMenuIsOpen: false,
            queries: {},
            sort: this.props.model.defaultSort,
        };

        // Get the fields which can be searched
        // Assign the default values to each filter value and mode
        // Setup the helper field collection
        Object.entries(this.props.model.fields).forEach(([name, field]) => {
            if (field.displayOnMain) {
                this.fields[name] = field;

                if (field.supportsQuery) {
                    this.filterFields[name] = field;
                    this.state.queries[name] = {
                        value: getDefaultValueForField(field),
                        mode: getDefaultModeForField(field),
                        field,
                    };
                }

                if (field.supportsSort) {
                    this.sortFields[name] = field;
                }
            }
        });
    }

    //
    // FETCH
    //

    fetch(page: number, includeQueries: boolean = true, includeSort: boolean = true) {
        this.props.cleanUpList(this.props.modeName);

        // Chances are, especially for big entities like Bill, once you search you want to see the list and not
        // a 1.5-screen long query menu
        this.setState({
            queryMenuIsOpen: false,
        });

        // We initialize the sorting object with undefined values
        // Check if they're still undefined
        const sort = includeSort && this.state.sort.field !== undefined && this.state.sort.fieldName !== undefined;

        // Pagination (hardcoded, for now)
        const pagination = {
            page,
            perPage: 15,
        }

        this.props.fetchList(
            this.props.model,
            this.props.modeName,
            includeQueries ? this.state.queries : null,
            sort ? this.state.sort : null,
            pagination,
        );
    }

    //
    // QUERY STATE CHANGE HANDLERS
    //

    changeQueryValue(id, value: any) {
        this.setState({
            queries: {
                ...this.state.queries,
                [id]: {
                    ...this.state.queries[id],
                    value,
                },
            },
        });
    }

    changeQueryMode(id, mode: FilterMode) {
        this.setState({
            queries: {
                ...this.state.queries,
                [id]: {
                    ...this.state.queries[id],
                    mode,
                },
            },
        });
    }

    changeSortField(id, fieldName: string) {
        this.setState({
            sort: {
                ...this.state.sort,
                fieldName,
                field: this.sortFields[fieldName],
            },
        });
    }

    changeSortOrder(id, order: SortOrder) {
        this.setState({
            sort: {
                ...this.state.sort,
                order,
            },
        });
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        // Fetch the first page
        this.fetch(1, false);
    }

    componentDidUpdate() {
        if (this.props.isDirty === true) {
            this.fetch(this.props.currentPage);
        }
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpList(this.props.modeName);
    }

    //
    // FILTER COMPONENTS
    //

    //
    // DEFAULT MODE SWITCHES
    //

    getStringModeSwitch(fieldName: string) {
        return (
            <SegmentedControl
                id={fieldName}
                data={stringModeVtDM}
                value={this.state.queries[fieldName].mode}
                onChange={this.changeQueryMode.bind(this)}
            />
        );
    }

    getNumberDateModeSwitch(fieldName: string) {
        return (
            <SegmentedControl
                id={fieldName}
                data={numberDateModeVtDM}
                value={this.state.queries[fieldName].mode}
                onChange={this.changeQueryMode.bind(this)}
            />
        );
    }

    //
    // SEARCH FIELDS
    //

    getSearchControls(): [FilterControl] {
        const fields = [];

        Object.entries(this.filterFields).forEach(([name, field]) => {
            let control: FilterControl;

            // TODO: Handle plural values
            switch (field.dataType.primitive) {
                case 'str':
                    control = {
                        fieldComponent: (
                            <ControlledInputField
                                id={name}
                                displayName={field.displayName}
                                inputType="text"
                                value={this.state.queries[name].value}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: this.getStringModeSwitch(name),
                    };
                    break;
                case 'num':
                    control = {
                        fieldComponent: (
                            <ControlledInputField
                                id={name}
                                displayName={field.displayName}
                                inputType="number"
                                value={this.state.queries[name].value}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: this.getNumberDateModeSwitch(name),
                    };
                    break;
                case 'ts':
                    control = {
                        fieldComponent: (
                            <ControlledInputField
                                id={name}
                                displayName={field.displayName}
                                inputType="date"
                                value={this.state.queries[name].value}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: this.getNumberDateModeSwitch(name),
                    };
                    break;
                case 'ref':
                    control = {
                        fieldComponent: (
                            <TypeaheadInputField
                                id={name}
                                displayName={field.displayName}
                                maxValues={field.dataType.extraData.maxValues}
                                initialValue={this.state.queries[name].value}
                                options={this.props.suggestions[field.dataType.extraData.model.classInternalName]}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: null,
                    };
                    break;
                case 'enum':
                    control = {
                        fieldComponent: (
                            <SegmentedControl
                                id={name}
                                displayName={field.displayName}
                                embedInFormGroup={true}
                                data={field.dataType.extraData}
                                value={this.state.queries[name].value}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: null,
                    };
                    break;
                case 'bool':
                    control = {
                        fieldComponent: (
                            <SegmentedControl
                                id={name}
                                displayName={field.displayName}
                                embedInFormGroup={true}
                                data={booleanVtDM}
                                value={this.state.queries[name].value}
                                onChange={this.changeQueryValue.bind(this)}
                            />
                        ),
                        modesComponent: null,
                    };
                    break;
                default:
                    control = null;
            }

            if (control !== null) {
                control.name = name;
            }

            fields.push(control);
        });

        return fields;
    }

    // SORT FIELDS

    getSortControls() {
        if (Object.keys(this.sortFields).length === 0) {
            return null;
        }

        const sortFieldVtDM: ValueToDisplayNameMap = {};

        Object.entries(this.sortFields).forEach(([name, field]) => {
            sortFieldVtDM[name] = field.displayName;
        });

        return (
            <div>
                <SegmentedControl
                    id="sortField"
                    displayName="Sort field"
                    embedInFormGroup={true}
                    data={sortFieldVtDM}
                    value={this.state.sort.fieldName}
                    onChange={this.changeSortField.bind(this)}
                />
                <SegmentedControl
                    id="sortOrder"
                    displayName="Sort order"
                    embedInFormGroup={true}
                    data={sortOrderVtDM}
                    value={this.state.sort.order}
                    onChange={this.changeSortOrder.bind(this)}
                />
            </div>
        );
    }

    //
    // RENDER
    //

    render() {
        let bodyComponent;
        let searchFields = [];
        let sortControls = null;

        if (
            this.props.suggestions === undefined ||
            this.props.entityList === undefined ||
            this.props.entityCount === undefined
        ) {
            // If any of the network resources are not here, display a loading indicator
            bodyComponent = <LoadingContainer/>;
        } else {
            const rowActions = [...this.props.rowActions];

            if (this.props.canDelete) {
                rowActions.push({
                    text: 'Delete',
                    modal: false,
                    handler: (id: string) => this.props.deleteEntity(this.props.model, this.props.modeName, id),
                });
            }

            // Set up the table rows from the entity list
            const rows = this.props.entityList.map((entity) => (
                <Row
                    key={getRandomString()}
                    fields={this.fields}
                    suggestions={this.props.suggestions}
                    entity={entity}
                    model={this.props.model}
                    rowActions={rowActions}
                />
            ));

            // Set up the corresponding table column headers
            const headers = Object.values(this.fields).map((field) => (
                <th key={getRandomString()}>{field.displayName}</th>
            ));

            // If there are actions for each specific entity, insert a column for actions
            if (this.props.rowActions.length > 0) {
                headers.push(<th key={getRandomString()}>Actions</th>);
            }

            // Create the table and the pagination control
            bodyComponent = (
                <div>
                    <Table responsive>
                        <thead>
                        <tr>{headers}</tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    <ListPagination
                        activePage={this.props.currentPage}
                        totalCount={this.props.entityCount}
                        onChange={(newPage) =>
                            // Fetch the list for the requested page when the page changes
                            this.fetch(newPage)
                        }
                    />
                </div>
            );

            // Get the search controls and arrange them properly
            searchFields = this.getSearchControls().map((filterControl) => (
                <div key={filterControl.name}>
                    {filterControl.fieldComponent}
                    <div className={StyleClasses.bottomSpacedElement}>{filterControl.modesComponent}</div>
                </div>
            ));

            sortControls = this.getSortControls();
        }

        // Set up the list-level actions, e.g. create an entity
        const actions = this.props.listActions.map((action) => {
            return action.handler ? (
                <Button
                    className={StyleClasses.spacedElement}
                    onClick={() => action.handler(this.props.history)}
                    key={getRandomString()}
                >
                    {action.text}
                </Button>
            ) : <div className={StyleClasses.spacedElement} onClick={() => this.fetch(this.props.currentPage)}>{action.component}</div>
        });

        // Display a message if search is not supported, or the search fields and the search button
        const searchAndSortComponent =
            searchFields.length === 0 || sortControls === null ? (
                <div className={StyleClasses.spacedElement} hidden={!this.state.queryMenuIsOpen}>
                    Search is not supported on this type of entity
                </div>
            ) : (
                <div className={StyleClasses.spacedElement} hidden={!this.state.queryMenuIsOpen}>
                    <h4>Searching</h4>
                    {searchFields}
                    <h4>Sorting</h4>
                    {sortControls}
                    <Button
                        className={StyleClasses.topAndBottomSpacedElement}
                        onClick={() => this.fetch(this.props.currentPage)}
                    >
                        Search
                    </Button>
                </div>
            );

        return (
            <div>
                {/* Header */}
                <h2 className={StyleClasses.spacedElement}>{this.props.titleGetter()}</h2>
                {/* Query menu */}
                <div className={StyleClasses.spacedElement}>
                    {/* Query menu toggle */}
                    <Button onClick={() => this.setState({queryMenuIsOpen: !this.state.queryMenuIsOpen})}>
                        {this.state.queryMenuIsOpen ? 'Hide query menu' : 'Show query menu'}
                    </Button>
                    {/* The filter controls or a message if there are none  */}
                    {searchAndSortComponent}
                </div>
                {/* List-level actions */}
                <div className="d-flex justify-content-end">{actions}</div>
                {/* The list itself */}
                {bodyComponent}
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    const appState: AppState = state.app;
    return appState.modes.availableModes[appState.modes.currentModeName];
}

export default withRouter(
    connect(mapStateToProps, {fetchList, cleanUpList, deleteEntity})(ListView),
);
