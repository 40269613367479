// @flow

import type { AddMode, SetRegistry, SwitchMode } from './types';
import type { PanelRegistry } from '../../ui/registry';

export function addMode(modeName: string): AddMode {
    return (dispatch) => {
        return dispatch({
            type: 'modes/add',
            modeName,
        });
    };
}

export function setRegistry(registry: PanelRegistry): SetRegistry {
    return (dispatch) => {
        return dispatch({
            type: 'modes/setRegistry',
            registry,
        });
    };
}

export function switchMode(modeName: string): SwitchMode {
    return (dispatch) => {
        return dispatch({
            type: 'modes/switch',
            modeName,
        });
    };
}
