// @flow
import type { ErrorsAction, ErrorsClearAction } from './types';

type PromiseActionType = Promise<ErrorsAction>;
type ThunkActionType = (dispatch: DispatchType) => any; // eslint-disable-line no-use-before-define
type DispatchType = (action: ErrorsAction | ThunkActionType | PromiseActionType | Array<ErrorsAction>) => any;

export const setErrorAction = (errorMessage: string): ThunkActionType => async (dispatch: DispatchType) => {
    const errorTimer = setTimeout(() => {
        dispatch({ type: 'errors/clear' });
    }, 5000);
    dispatch({
        type: 'errors/set',
        errorMessage,
        errorTimer,
    });
};

export const clearErrorsAction = (): ErrorsClearAction => ({ type: 'errors/clear' });
