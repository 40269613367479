// @flow

import {getActionButton} from "../utils";
import {Component} from "react";
import {connect} from "react-redux";
import {computeUserScores} from "../../store/custom/actions";
import {fetchList} from "../../store/list/actions";

//
// PROPS
//

type PropsType = {
    // INJECTED FUNCTIONS
    computeUserScores: () => void
}

//
// STATE
//

type StateType = {}

//
// CLASS
//

class ComputeUserScoresButton extends Component<PropsType, StateType> {
    render() {
        return getActionButton('Compute Scores', () => {this.props.computeUserScores()})
    }
}

export default connect(null, { computeUserScores, fetchList })(ComputeUserScoresButton);
