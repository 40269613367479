// @flow

import { combineReducers } from 'redux';
import { reduce as errors } from './errors/reducers';
import { reduceAccessControl as accessControl } from './accessControl/reducers';
import { reduceApp as app } from './app/reducers';

export default combineReducers({
    accessControl,
    errors,
    app,
});
