// @flow

import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import StyleClasses from '../../../styles/StyleClasses';

type PropTypes = {
    errorString: string,
};

class ErrorAlert extends Component<PropTypes, {}> {
    render() {
        return (
            <Alert color="danger" className={StyleClasses.elementWithSharpCorners}>
                {this.props.errorString}
            </Alert>
        );
    }
}

export default ErrorAlert;
