// @flow

import type { AppState, AppAction } from './types';
import { getDefaultModesState, reduceModes } from '../modes/reducers';

function getDefaultAppState(): AppState {
    return {
        governor: undefined,
        policy: undefined,
        initializationStatus: undefined,
        modes: getDefaultModesState(),
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceApp(state: AppState = getDefaultAppState(), action: AppAction): AppAction {
    switch (action.type) {
        case 'app/receiveGovernor':
            return {
                ...state,
                governor: action.governor,
            };

        case 'app/receivePolicy':
            return {
                ...state,
                policy: action.policy,
            };

        case 'app/setInitializationStatus':
            return {
                ...state,
                initializationStatus: action.is,
            };

        default:
            if (
                action.type.startsWith('create') ||
                action.type.startsWith('edit') ||
                action.type.startsWith('info') ||
                action.type.startsWith('list') ||
                action.type.startsWith('modes')
            ) {
                return {
                    ...state,
                    modes: reduceModes(state.modes, action),
                };
            }
            return state;
    }
}
