// @flow

import type { ReceiveList, CleanUpList, MarkDirty } from './types';

import { deleteRequest, jsonGetRequest } from '../requests';
import { setErrorAction } from '../errors/actions';
import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type {
    PaginationParams,
    QueryList,
    SortParams,
} from '../../entities/types';

function receiveList(
    model: $Shape<StoredEntity>,
    modeName: string,
    response: { list: Array<Object>, suggestions: {} },
    count: any,
    currentPage: number,
): ReceiveList {
    return {
        type: 'list/receive',
        modeName,
        // eslint-disable-next-line new-cap
        entityList: response.list.map((record) => new model(record)),
        entityCount: count.count,
        suggestions: response.suggestions,
        currentPage,
    };
}

export function fetchList(
    model: $Shape<StoredEntity>,
    modeName: string,
    queryList: ?QueryList,
    sortParams: ?SortParams,
    paginationParams: PaginationParams,
) {
    return (dispatch) => {
        const listPromise = jsonGetRequest(model.endpoints.list(queryList, sortParams, paginationParams));
        const countPromise = jsonGetRequest(model.endpoints.count(queryList));

        Promise.all([listPromise, countPromise])
            .then((values) => dispatch(receiveList(model, modeName, values[0], values[1], paginationParams.page)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

function markDirty(modeName: string): MarkDirty {
    return {
        type: 'list/markDirty',
        modeName,
    };
}

export function deleteEntity(model: $Shape<StoredEntity>, modeName: string, id: string) {
    return (dispatch) => {
        deleteRequest(model.endpoints.info(id))
            .then(() => dispatch(markDirty(modeName)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

export function cleanUpList(modeName: string): CleanUpList {
    return {
        type: 'list/cleanUp',
        modeName,
    };
}
