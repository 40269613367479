// @flow

import type { ListState, ListAction } from './types';

export function getDefaultListState(): ListState {
    return {
        entityList: undefined,
        suggestions: undefined,
        entityCount: undefined,
        currentPage: undefined,
        isDirty: undefined,
    };
}

export default function reduceList(state: ListState, action: ListAction): ListState {
    switch (action.type) {
        case 'list/receiveSuggestions':
            return {
                ...state,
                suggestions: action.suggestions,
            };

        case 'list/receive':
            return {
                ...state,
                entityList: action.entityList,
                entityCount: action.entityCount,
                currentPage: action.currentPage,
                suggestions: action.suggestions,
                isDirty: false,
            };

        case 'list/markDirty':
            return {
                ...state,
                isDirty: true,
            };

        case 'list/cleanUp':
            return getDefaultListState();

        default:
            return state;
    }
}
