// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import type {StoredEntity} from "../abstract/StoredEntity";
import {createdAtField, getCreatedAtSort, idField} from "../abstract/StoredEntity";
import RewardProgram from "./RewardProgram";
import Institution from "./Institution";
import {constructEndpoints} from "../helpers";
import {Geography} from "../types";

class InstitutionOffer implements StoredEntity {
    static classInternalName = 'institution_offer';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        institutionId: {
            displayName: 'Institution',
            recordName: 'institution_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Institution
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        rewardProgramId: {
            displayName: 'Reward Program',
            recordName: 'reward_program_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: RewardProgram,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        offerType: {
            displayName: 'Type',
            recordName: 'offer_type',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Referral',
                    2: 'Affiliate'
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        geography: {
            displayName: 'Geography',
            recordName: 'geography',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: Geography,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        wb: {
            displayName: 'WB',
            recordName: 'wb',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        description: {
            displayName: 'Description',
            recordName: 'description',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        netValue: {
            displayName: 'Net Value',
            recordName: 'net_value',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[InstitutionOffer.fields.id.recordName];
    }

    get displayName() {
        return this.record[InstitutionOffer.fields.id.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('institution-offers');

    constructor(record: any) {
        this.record = record;
    }
}

export default InstitutionOffer;
