import {jsonPostRequest} from "../requests";
import {setErrorAction} from "../errors/actions";
import User from "../../entities/models/User";

export function computeUserScores() {
    return (dispatch) => {
        jsonPostRequest(User.endpoints.computeScores(), {})
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}
