class StyleClasses {
    /*
     * A wrapper around CSS class names.
     */

    static get centeredElement() {
        return 'centered-element';
    }

    static get littleSpacedElement() {
        return 'little-spaced-element';
    }

    static get spacedElement() {
        return 'spaced-element';
    }

    static get lotSpacedElement() {
        return 'lot-spaced-element';
    }

    static get bottomSpacedElement() {
        return 'bottom-spaced-element';
    }

    static get elementWithSharpCorners() {
        return 'alert-with-sharp-corners';
    }

    static get topAndBottomSpacedElement() {
        return 'top-and-bottom-spaced-element';
    }

    static get filterInputGroup() {
        return 'filter-input-group';
    }

    static get verticallyAlignedTableRow() {
        return 'vertically-aligned-table-row';
    }
}

export default StyleClasses;
