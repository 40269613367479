// @flow

import type { EditFormState, EditFormAction } from './types';

export function getDefaultEditFormState(): EditFormState {
    return {
        entity: undefined,
        suggestions: undefined,
        editStatus: undefined,
    };
}

export default function reduceEditForm(state: EditFormState, action: EditFormAction): EditFormState {
    switch (action.type) {
        case 'edit/receiveEntity':
            return {
                ...state,
                entity: action.entity,
                suggestions: action.suggestions
            };

        case 'edit/request':
            return {
                ...state,
                editStatus: 'pending',
            };

        case 'edit/receiveStatus':
            return {
                ...state,
                editStatus: action.status,
            };

        case 'edit/cleanUp':
            return getDefaultEditFormState();

        default:
            return state;
    }
}
