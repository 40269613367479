// @flow

import type { CreateFormState, CreateFormAction } from './types';

export function getDefaultCreateFormState(): CreateFormState {
    return {
        suggestions: undefined,
        createStatus: undefined,
    };
}

export default function reduceCreateForm(state: CreateFormState, action: CreateFormAction): CreateFormState {
    switch (action.type) {
        case 'create/receiveSuggestions':
            return {
                ...state,
                suggestions: action.suggestions,
            };

        case 'create/request':
            return {
                ...state,
                createStatus: 'pending',
            };

        case 'create/receiveStatus':
            return {
                ...state,
                createStatus: action.status,
            };

        case 'create/cleanUp':
            return getDefaultCreateFormState();

        default:
            return state;
    }
}
