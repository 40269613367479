// @flow

import React, { Component } from 'react';
import { Form, Button, Container } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import StyleClasses from '../../styles/StyleClasses';
import LoadingContainer from '../basicElements/LoadingContainer';
import ControlledInputField from '../basicElements/inputFields/ControlledInputField';
import TypeaheadInputField from '../basicElements/inputFields/TypeaheadInputField';
import { RequestStatus } from '../../store/requests';

import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type {EntityFieldCollection, Suggestions} from '../../entities/types';
import { getDefaultValueForField } from '../helpers';
import BooleanControlledInputField from '../basicElements/inputFields/BooleanControlledInputField';
import type { AppState } from '../../store/app/types';
import type { InternalFormData} from '../types';
import { fetchSuggestions, create, cleanUpCreate } from '../../store/create/actions';
import FileInputField from '../basicElements/inputFields/FileInputField';

//
// PROPS
//

type PropsType = {
    // MODE
    model: $Shape<StoredEntity>,
    modeName: string,
    titleGetter: () => string,
    // INJECTED FUNCTIONS
    fetchSuggestions: (modeName: string, fields: EntityFieldCollection) => void,
    create: (
        model: $Shape<StoredEntity>,
        modeName: string,
        fields: EntityFieldCollection,
        fileFields: EntityFieldCollection,
        data: InternalFormData,
    ) => void,
    onSuccess: (history: any) => void,
    cleanUpCreate: (modeName: string) => void,
    // STATE
    suggestions: Suggestions,
    createStatus: RequestStatus,
    // ROUTER
    history: any,
};

//
// CLASS
//

class CreateForm extends Component<PropsType, {}> {
    fields: EntityFieldCollection;

    constructor(props) {
        super(props);
        this.fields = {};
        this.fileFields = {};
        this.state = {};

        Object.entries(this.props.model.fields).forEach(([name, field]) => {
            if (field.needToCreate) {
                this.fields[name] = field;
                this.state[name] = getDefaultValueForField(field);
            }
        });

        Object.entries(this.props.model.fileFields).forEach(([name, field]) => {
            this.fileFields[name] = field;
            this.state[name] = null;
        });
    }

    changeFormState(id, value) {
        this.setState({
            [id]: value,
        });
    }

    submit(event) {
        event.preventDefault();
        this.props.create(this.props.model, this.props.modeName, this.fields, this.fileFields, this.state);
    }

    componentDidMount() {
        const fieldsToFetch = {};

        Object.entries(this.fields).forEach(([name, field]) => {
            if (field.dataType.primitive === 'ref') {
                fieldsToFetch[name] = field;
            }
        });

        this.props.fetchSuggestions(this.props.modeName, fieldsToFetch);
    }

    componentDidUpdate(): * {
        if (['ok', 'partial'].includes(this.props.createStatus)) {
            this.props.onSuccess(this.props.history);
        }
    }

    componentWillUnmount(): void {
        this.props.cleanUpCreate(this.props.modeName);
    }

    getControlsForFields(): [Component] {
        const fields = [];
        Object.entries(this.fields).forEach(([name, field]) => {
            let control;

            // TODO: Handle plural values
            switch (field.dataType.primitive) {
                case 'str':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="text"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'simple-entity':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="textarea"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'num':
                case 'enum': // TODO: Make it a segmented control
                case 'ts': // TODO: Make it a date picker
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="number"
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                case 'ref':
                    control = (
                        <TypeaheadInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            maxValues={field.dataType.extraData.maxValues}
                            initialValue={this.state[name]}
                            options={this.props.suggestions[field.dataType.extraData.model.classInternalName]}
                            onChange={(id, values) => this.changeFormState(id, values)}
                        />
                    );
                    break;
                case 'bool':
                    control = (
                        <BooleanControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            value={this.state[name]}
                            onChange={(id, value) => this.changeFormState(id, value)}
                        />
                    );
                    break;
                default:
                    break;
            }

            fields.push(control);
        });

        return fields;
    }

    getControlsForFileFields(): [Component] {
        const fileFields = [];
        Object.entries(this.fileFields).forEach(([name, field]) => {
            const control = (
                <FileInputField
                    id={name}
                    key={name}
                    displayName={field.displayName}
                    onFileUpdated={(id, value) => this.changeFormState(id, value)}
                />
            );
            fileFields.push(control);
        });
        return fileFields;
    }

    render() {
        if (this.props.suggestions === undefined || this.props.createStatus === 'pending') {
            return <LoadingContainer />;
        }

        return (
            <div>
                <h2 className={StyleClasses.spacedElement}>{this.props.titleGetter()}</h2>
                <Container fluid>
                    <Form onSubmit={(event) => this.submit(event)}>
                        {this.getControlsForFields()}
                        {this.getControlsForFileFields()}
                        <Button className={StyleClasses.bottomSpacedElement}>Submit</Button>
                    </Form>
                </Container>
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    const appState: AppState = state.app;
    return appState.modes.availableModes[appState.modes.currentModeName];
}

export default withRouter(
    connect(mapStateToProps, {
        fetchSuggestions,
        create,
        cleanUpCreate,
    })(CreateForm),
);
