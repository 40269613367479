// @flow

import { SimpleEntity } from '../abstract/SimpleEntity';
import type { EntityFieldCollection } from '../types';

class UrlClick implements SimpleEntity {
    static fields: EntityFieldCollection = {
        timestamp: {
            displayName: 'Time',
            recordName: 'timestamp',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null
            },
            isOptional: false,
        },
        ip: {
            displayName: 'IP',
            recordName: 'ip',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null
            },
            isOptional: false,
        },
        location: {
            displayName: 'location',
            recordName: 'location',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null
            },
            isOptional: false,
        },
    };

    constructor(record: any) {
        this.record = record;
    }

    get id() {
        return this.record[UrlClick.fields.id.recordName];
    }
}

export default UrlClick;
