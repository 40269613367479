// @flow

import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { convertFieldValuesToComponents } from '../helpers';
import StyleClasses from '../../styles/StyleClasses';

import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type { RowAction} from '../types';
import type {StoredEntityField, Suggestions} from '../../entities/types';
import { getActionButton, getRandomString } from '../utils';

//
// PROPS
//

type PropsType = {
    model: $Shape<StoredEntity>,
    fields: [StoredEntityField],
    entity: StoredEntity,
    suggestions: Suggestions,
    history: any,
    rowActions: [RowAction],
};

//
// CLASS
//

class ListRow extends Component<PropsType> {
    render() {
        const values = convertFieldValuesToComponents(this.props.fields, this.props.entity, this.props.suggestions);

        const actions = this.props.rowActions.map((action) => {
            const handler = action.modal
                ? () => action.handler(this.props.history, this.props.entity.id)
                : () => action.handler(this.props.entity.id);
            return getActionButton(action.text, handler);
        });

        return (
            <tr className={StyleClasses.verticallyAlignedTableRow}>
                {values.map((value) => (
                    <td key={getRandomString()}>{value}</td>
                ))}
                {this.props.rowActions.length > 0 ? <td key={getRandomString()}>{actions}</td> : null}
            </tr>
        );
    }
}

//
// REDUX & ROUTER
//

export default withRouter(connect(null, null)(ListRow));
