// @flow

import { SimpleEntity } from '../abstract/SimpleEntity';
import type { EntityFieldCollection } from '../types';

class PolicyEntityAction implements SimpleEntity {
    static fields: EntityFieldCollection = {
        entityType: {
            displayName: 'Entity Type',
            recordName: 'entity_type',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            isOptional: false,
        },
        operations: {
            displayName: 'Operations',
            recordName: 'operations',
            dataType: {
                primitive: 'enum',
                isArray: true,
                extraData: {
                    1: 'Create',
                    2: 'Read',
                    3: 'Update',
                },
            },
            isOptional: false,
        },
    };

    constructor(record: any) {
        this.record = record;
    }
}

export default PolicyEntityAction;
