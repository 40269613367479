// @flow

import React from 'react';
import { Table } from 'reactstrap';
import type {StoredEntityField, Suggestions} from '../entities/types';
import type { StoredEntity } from '../entities/abstract/StoredEntity';
import type { SimpleEntity } from '../entities/abstract/SimpleEntity';
import ListRow from './list/ListRow';
import { getRandomString } from './utils';
import ShowHideComponent from './basicElements/ShowHideComponent';

export function getDefaultValueForField(field: StoredEntityField) {
    switch (field.dataType.primitive) {
        case 'ref':
            return [];
        case 'bool':
            return false;
        default:
            return '';
    }
}

function getFlexColumnForList(valueList, valueGetter) {
    const elements = valueList.map((v) => <div key={getRandomString()}>{valueGetter(v)}</div>);

    return <div style={{ display: 'flex', flexDirection: 'column' }}>{elements}</div>;
}

function componentForPrimitiveField(field, value, valueGetter) {
    if (field.dataType.isArray) {
        return getFlexColumnForList(value, valueGetter);
    }
    return <div style={{wordBreak: 'break-all'}}>{valueGetter(value)}</div>;
}

function partOfString(s) {
    if (s.length < 100)
        return s;
    return `${s.slice(0, 100)}…`
}

export function convertFieldValuesToComponents(
    fields: [StoredEntityField],
    e: StoredEntity | SimpleEntity,
    suggestions: Suggestions,
    fullStrings: boolean = false
) {
    return Object.entries(fields).map(([name, field]) => {
        const fieldValue = e.record[field.recordName];

        // If the field is optional and nil or an empty array, display 'None'
        if ((field.isOptional && !fieldValue) || (field.dataType.isArray && fieldValue.length === 0)) {
            return 'None';
        }

        // noinspection FallThroughInSwitchStatementJS
        switch (field.dataType.primitive) {
            case 'ts': {
                // Convert the date to a human-readable local date
                return componentForPrimitiveField(field, fieldValue, (v) => new Date(v * 1000).toLocaleString());
            }
            case 'bool': {
                // Convert the bool to Yes or No
                return componentForPrimitiveField(field, fieldValue, (v) => (v ? 'Yes' : 'No'));
            }
            case 'enum': {
                // Convert the enum value to its string representation
                return componentForPrimitiveField(field, fieldValue, (v) => field.dataType.extraData[v]);
            }
            case 'ref': {
                // Get the list of suggestions
                const sRecord = suggestions[field.dataType.extraData.model.classInternalName];
                // Get the record(s) with matching ID(s) and use the display name
                let displayName = '';
                if (field.dataType.isArray) {
                    displayName = sRecord
                        .filter((r) => fieldValue.includes(r.id))
                        .map((o) => o['display_name'])
                        .join(', ');
                } else {
                    displayName = sRecord.filter((r) => fieldValue === r.id)[0]['display_name'];
                }
                return displayName;
            }
            case 'simple-entity': {
                // Basically construct a table

                // Get the table headers from each field
                const headers = Object.values(field.dataType.extraData.model.fields).map((f) => (
                    <th key={getRandomString()}>{f.displayName}</th>
                ));

                // If it's an array, map the records to objects
                // Otherwise, just wrap the record in an array
                const obj = field.dataType.isArray
                    ? fieldValue.map((r) => new field.dataType.extraData.model(r))
                    : [new field.dataType.extraData.model(fieldValue)];

                // Get a row for each array element
                const rows = obj.map((o) => (
                    <ListRow
                        key={getRandomString()}
                        model={field.dataType.extraData.model}
                        fields={field.dataType.extraData.model.fields}
                        entity={o}
                        rowActions={[]}
                        suggestions={{}}
                    />
                ));

                return (
                    <ShowHideComponent
                        child={
                            <Table responsive bordered>
                                <thead>
                                <tr>{headers}</tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        }
                    />
                );
            }
            case 'str':
                if (!fullStrings)
                    return componentForPrimitiveField(field, fieldValue, partOfString);
            case 'num':
            default: {
                // Just return the value as-is
                return componentForPrimitiveField(field, fieldValue, (v) => v);
            }
        }
    });
}
