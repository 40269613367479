// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';

class User implements StoredEntity {
    static classInternalName = 'user';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        name: {
            displayName: 'Name',
            recordName: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        userId: {
            displayName: 'User ID',
            recordName: 'user_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        email: {
            displayName: 'Email',
            recordName: 'email',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        isTeam: {
            displayName: 'Is Team',
            recordName: 'is_team',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        redditId: {
            displayName: 'Reddit ID',
            recordName: 'reddit_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        referralScore: {
            displayName: 'Referral Score',
            recordName: 'referral_score',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        scoreComputedAt: {
            displayName: 'Reddit Score Computed Date',
            recordName: 'score_computed_at',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        redditCreatedAt: {
            displayName: 'Reddit Account Creation Date',
            recordName: 'reddit_created_at',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[User.fields.id.recordName];
    }

    get displayName() {
        return this.record[User.fields.email.recordName];
    }

    static endpoints: Endpoints = {
        ...constructEndpoints('users'),
        computeScores: () => 'users/score'
    };

    constructor(record: any) {
        this.record = record;
    }
}

export default User;
