// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import type { RouterHistory } from 'react-router';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import AuthToken from '../../entities/AuthToken';
import { getRandomString } from '../utils';
import type { ModeCollection } from '../modes';

type PropsType = {
    currentModeName: string,
    modes: ModeCollection,
    history: RouterHistory,
};

type StateType = {
    menuIsOpen: boolean,
    dropdownIsOpen: boolean,
};

class SiteNavbar extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);
        this.state = {
            menuIsOpen: false,
            dropdownIsOpen: false,
        };
    }

    buildNavItem(path: string, active: boolean, text: string): Component {
        const { history } = this.props;
        return (
            <NavItem key={getRandomString()}>
                <NavLink onClick={() => history.push(`${path}`)} active={active}>
                    {text}
                </NavLink>
            </NavItem>
        );
    }

    buildDropdownItem(path: string, active: boolean, text: string): Component {
        const { history } = this.props;
        return (
            <DropdownItem onClick={() => history.push(`${path}`)} active={active} key={getRandomString()}>
                {text}
            </DropdownItem>
        );
    }

    toggleNavbar() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen,
        });
    }

    toggleDropdown() {
        this.setState({
            dropdownIsOpen: !this.state.dropdownIsOpen,
        });
    }

    render() {
        const mainNavbarItems = [];
        const dropdownItems = [];

        Object.entries(this.props.modes).forEach(([name, mode]) => {
            if (mode.priority === 1) {
                mainNavbarItems.push(
                    this.buildNavItem(mode.routePath, name === this.props.currentModeName, mode.navbarTitle),
                );
            } else if (mode.priority === 2) {
                dropdownItems.push(
                    this.buildDropdownItem(mode.routePath, name === this.props.currentModeName, mode.navbarTitle),
                );
            }
        });

        const dropdown = dropdownItems ? (
            <Dropdown
                nav
                isOpen={this.state.dropdownIsOpen}
                toggle={() => this.toggleDropdown()}
                active={
                    this.props.currentModeName ? this.props.modes[this.props.currentModeName].priority === 2 : false
                }
            >
                <DropdownToggle nav caret>
                    Others
                </DropdownToggle>
                <DropdownMenu>{dropdownItems}</DropdownMenu>
            </Dropdown>
        ) : null;

        return (
            <Navbar light expand="md" pills="true">
                <NavbarBrand href="/">Churning Canada Backoffice</NavbarBrand>
                <NavbarToggler onClick={() => this.toggleNavbar()} />
                <Collapse isOpen={this.state.menuIsOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {mainNavbarItems}
                        {dropdown}
                        <NavItem>
                            <NavLink
                                onClick={() => {
                                    AuthToken.unset();
                                    window.location.replace('/');
                                }}
                            >
                                Logout
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default withRouter(SiteNavbar);
