// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import { createdAtField, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';
import {AscendingSortOrder} from "../types";
import Institution from "./Institution";
import RewardProgram from "./RewardProgram";

class CreditCard implements StoredEntity {
    static classInternalName = 'credit_card';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        institutionId: {
            displayName: 'Institution',
            recordName: 'institution_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Institution
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        shortId: {
            displayName: 'Short ID',
            recordName: 'short_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        rewardProgramId: {
            displayName: 'Reward Program',
            recordName: 'reward_program_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: RewardProgram,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        name: {
            displayName: 'Name',
            recordName: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        network: {
            displayName: 'Network',
            recordName: 'network',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Amex',
                    2: 'Visa',
                    3: 'MasterCard'
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        isTop: {
            displayName: 'Is Top',
            recordName: 'is_top',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {
        logo: {
            displayName: 'Photo',
            fileType: 'img',
            fileName: `photo`,
            displayOnMain: true,
        },
    };

    static defaultSort: SortParams = {
        fieldName: 'name',
        field: Institution.fields.name,
        order: AscendingSortOrder,
    };

    get id() {
        return this.record[CreditCard.fields.id.recordName];
    }

    get displayName() {
        return this.record[CreditCard.fields.name.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('credit-cards');

    constructor(record: any) {
        this.record = record;
    }
}

export default CreditCard;
