// @flow

import type { ModesAction, ModesState } from './types';
import { getAllModes } from '../../ui/modes';
import type { ListAction } from '../list/types';

export function getDefaultModesState(): ModesState {
    return {
        registry: undefined,
        currentModeName: undefined,
        availableModes: {},
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceModes(state: ModesState, action: ModesAction | ListAction): ModesState {
    const defaultModes = getAllModes();

    switch (action.type) {
        case 'modes/setRegistry':
            return {
                ...state,
                registry: action.registry,
            };

        case 'modes/add':
            if (state.registry === undefined) {
                throw new Error('Cannot add mode, no registry');
            }

            return {
                ...state,
                availableModes: {
                    ...state.availableModes,
                    [action.modeName]: defaultModes[action.modeName].initialStateGetter(),
                },
            };

        case 'modes/switch':
            if (state.registry === undefined) {
                throw new Error('Cannot switch mode, no registry');
            }

            return {
                ...state,
                currentModeName: action.modeName,
            };

        default: {
            if (state.registry === undefined) {
                throw new Error('Cannot reduce mode, no registry');
            }

            const mn = action.modeName;
            if (Object.prototype.hasOwnProperty.call(state.availableModes, mn)) {
                return {
                    ...state,
                    availableModes: {
                        ...state.availableModes,
                        [mn]: defaultModes[mn].reducer(state.availableModes[mn], action),
                    },
                };
            }

            return state;
        }
    }
}
