// @flow

import React, { Component } from 'react';
import { Form, Button, Container } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import LoadingContainer from '../basicElements/LoadingContainer';

import { login } from '../../store/accessControl/actions';
import { RequestStatus } from '../../store/requests';

import ErrorWrapper from '../basicElements/errors/ErrorWrapper';

import StyleClasses from '../../styles/StyleClasses';
import ControlledInputField from '../basicElements/inputFields/ControlledInputField';

type PropsType = {
    emailSuggestion: String,
    requestStatus: ?RequestStatus,
    login: Function,
    history: any,
};

type StateType = {
    email: String,
    password: String,
};

class LoginForm extends Component<PropsType, StateType> {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.emailSuggestion,
            password: '',
        };
    }

    changeFormState(id, value) {
        this.setState({
            [id]: value,
        });
    }

    submit(event) {
        event.preventDefault();
        this.props.login(this.state);
    }

    render() {
        if (this.props.requestStatus === 'pending') {
            return <LoadingContainer />;
        }

        const inputFields = [
            <ControlledInputField
                displayName="Email"
                key="email"
                inputType="email"
                id="email"
                value={this.state.email}
                onChange={(id, value) => this.changeFormState(id, value)}
            />,
            <ControlledInputField
                displayName="Password"
                key="password"
                inputType="password"
                id="password"
                value={this.state.password}
                onChange={(id, value) => this.changeFormState(id, value)}
            />,
        ];

        return (
            <div>
                <ErrorWrapper>
                    <h2 className={StyleClasses.spacedElement}>Login</h2>
                    <Container>
                        <Form onSubmit={(event) => this.submit(event)}>
                            {inputFields}
                            <Button className={StyleClasses.bottomSpacedElement}>Login</Button>
                        </Form>
                    </Container>
                </ErrorWrapper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loginStatus, emailSuggestion } = state.accessControl;
    return {
        requestStatus: loginStatus,
        emailSuggestion,
    };
}

export default withRouter(connect(mapStateToProps, { login })(LoginForm));
