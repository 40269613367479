// @flow

import React, {Component} from 'react';
import ListView from './list/ListView';
import type {PanelRegistry, RegistryEntityActionCollection} from './registry';
import {SuperRegistry} from './registry';
import reduceList, {getDefaultListState} from '../store/list/reducers';
import CreateForm from './create/CreateForm';
import EditForm from './edit/EditForm';
import InfoView from './info/InfoView';
import reduceCreateForm, {getDefaultCreateFormState} from '../store/create/reducers';
import reduceEditForm, {getDefaultEditFormState} from '../store/edit/reducers';
import reduceInfo, {getDefaultInfoState} from '../store/info/reducers';
import type {StoredEntity} from '../entities/abstract/StoredEntity';
import type {RowAction, TopLevelAction} from './types';
import Admin from '../entities/models/Admin';
import AdminRT from '../entities/models/AdminRT';
import User from '../entities/models/User';
import Policy from '../entities/models/Policy';
import EmailCheck from "../entities/models/EmailCheck";
import Institution from "../entities/models/Institution";
import CreditCard from "../entities/models/CreditCard";
import RewardProgram from "../entities/models/RewardProgram";
import RedditCheck from "../entities/models/RedditCheck";
import ComputeUserScoresButton from "./custom/ComputeUserScoresButton";
import CreditCardOffer from "../entities/models/CreditCardOffer";
import CreditCardReferral from "../entities/models/CreditCardReferral";
import InstitutionReferral from "../entities/models/InstitutionReferral";
import InstitutionOffer from "../entities/models/InstitutionOffer";

//
//
// MODE TYPEDEF
//
//

// 1 - Display in navbar
// 2 - Display in navbar dropdown
// undefined - N/A
export type ModePriority = 1 | 2 | undefined;

// A collection of data about any given standalone page
export type Mode = {
    // CORE
    component: Component,
    priority: ModePriority,
    // TITLE
    navbarTitle: ?string,
    // ROUTER
    routePath: string,
    // REDUX
    initialStateGetter: () => any,
    reducer: (state: any, action: any) => void,
};

export type ModeCollection = {
    [key: string]: Mode,
};

export type StandardModeType = 'list' | 'info' | 'create' | 'edit';

//
//
// HELPERS
//
//

function getStandardModeName(entityType: $Shape<StoredEntity>, modeType: StandardModeType) {
    return `${entityType.classInternalName}-${modeType}`;
}

function getStandardModeHandler(entityType: $Shape<StoredEntity>, modeType: StandardModeType) {
    const root = `/${getStandardModeName(entityType, modeType)}`;

    switch (modeType) {
        case 'create':
        case 'list':
            return (history: any) => {
                history.push(root);
            };
        case 'info':
        case 'edit':
            return (history: any, id: string) => {
                history.push(`${root}/${id}`);
            };
        default:
            return undefined;
    }
}

function getStandardModeUrl(entityType: $Shape<StoredEntity>, modeType: StandardModeType) {
    const root = `/${getStandardModeName(entityType, modeType)}`;

    switch (modeType) {
        case 'create':
        case 'list':
            return root;
        case 'info':
        case 'edit':
            return `${root}/:id`;
        default:
            return undefined;
    }
}

//
//
// MODE GETTERS
//
//

export function getListMode(
    entityType: $Shape<StoredEntity>,
    canCreate: boolean,
    canUpdate: boolean,
    canDelete: boolean,
    priority: ModePriority,
    panelTitleGetter: () => string,
    navbarTitle: ?string,
    customListActions: [TopLevelAction] = [],
    customRowActions: [RowAction] = [],
): Mode {
    // List actions

    let listActions = [];

    if (canCreate) {
        const createNewAction = {
            text: 'Create New',
            handler: getStandardModeHandler(entityType, 'create'),
        };
        listActions = [createNewAction];
    }

    if (customListActions) {
        listActions = [...listActions, ...customListActions];
    }

    // Row actions

    const infoAction = {
        text: 'Info',
        modal: true,
        handler: getStandardModeHandler(entityType, 'info'),
    };

    let rowActions = [infoAction];

    if (canUpdate) {
        rowActions.push({
            text: 'Edit',
            modal: true,
            handler: getStandardModeHandler(entityType, 'edit'),
        });
    }

    if (customRowActions) {
        rowActions = [...rowActions, ...customRowActions];
    }

    return {
        component: (
            <ListView
                model={entityType}
                modeName={getStandardModeName(entityType, 'list')}
                listActions={listActions}
                rowActions={rowActions}
                titleGetter={panelTitleGetter}
                canDelete={canDelete}
            />
        ),
        priority,
        navbarTitle,
        initialStateGetter: getDefaultListState,
        reducer: reduceList,
        routePath: getStandardModeUrl(entityType, 'list'),
    };
}

function getListModeFromRegistry(
    registry: RegistryEntityActionCollection,
    entityType: $Shape<StoredEntity>,
    priority: ModePriority,
    panelTitleGetter: () => string,
    navbarTitle: string,
    customListActions: [TopLevelAction] = [],
    customRowActions: [RowAction] = [],
) {
    const registryRecord = registry[entityType.classInternalName];

    if (registryRecord.operations.r) {
        return getListMode(
            entityType,
            registryRecord.operations.c,
            registryRecord.operations.u,
            registryRecord.operations.d,
            priority,
            panelTitleGetter,
            navbarTitle,
            customListActions,
            customRowActions,
        );
    }
    return null;
}

export function getInfoMode(entityType: $Shape<StoredEntity>, panelTitleGetter: (entity: StoredEntity) => string) {
    return {
        component: (
            <InfoView
                model={entityType}
                modeName={getStandardModeName(entityType, 'info')}
                actions={[]}
                titleGetter={panelTitleGetter}
            />
        ),
        priority: undefined,
        navbarTitle: null,
        initialStateGetter: getDefaultInfoState,
        reducer: reduceInfo,
        routePath: getStandardModeUrl(entityType, 'info'),
    };
}

function getInfoModeFromRegistry(
    registry: RegistryEntityActionCollection,
    entityType: $Shape<StoredEntity>,
    panelTitleGetter: (entity: StoredEntity) => string,
) {
    const registryRecord = registry[entityType.classInternalName];

    if (registryRecord.operations.r) {
        return getInfoMode(entityType, panelTitleGetter);
    }
    return null;
}

export function getCreateMode(entityType: $Shape<StoredEntity>, panelTitleGetter: () => string) {
    return {
        component: (
            <CreateForm
                model={entityType}
                modeName={getStandardModeName(entityType, 'create')}
                onSuccess={getStandardModeHandler(entityType, 'list')}
                titleGetter={panelTitleGetter}
            />
        ),
        priority: undefined,
        panelTitleGetter,
        navbarTitle: null,
        initialStateGetter: getDefaultCreateFormState,
        reducer: reduceCreateForm,
        routePath: getStandardModeUrl(entityType, 'create'),
    };
}

function getCreateModeFromRegistry(
    registry: RegistryEntityActionCollection,
    entityType: $Shape<StoredEntity>,
    panelTitleGetter: (entity: StoredEntity) => string,
) {
    const registryRecord = registry[entityType.classInternalName];

    if (registryRecord.operations.c) {
        return getCreateMode(entityType, panelTitleGetter);
    }
    return null;
}

export function getEditMode(entityType: $Shape<StoredEntity>, panelTitleGetter: (entity: StoredEntity) => string) {
    return {
        component: (
            <EditForm
                model={entityType}
                modeName={getStandardModeName(entityType, 'edit')}
                onSuccess={getStandardModeHandler(entityType, 'list')}
                titleGetter={panelTitleGetter}
            />
        ),
        priority: undefined,
        panelTitleGetter,
        navbarTitle: null,
        initialStateGetter: getDefaultEditFormState,
        reducer: reduceEditForm,
        routePath: getStandardModeUrl(entityType, 'edit'),
    };
}

function getEditModeFromRegistry(
    registry: RegistryEntityActionCollection,
    entityType: $Shape<StoredEntity>,
    panelTitleGetter: () => string,
) {
    const registryRecord = registry[entityType.classInternalName];

    if (registryRecord.operations.u) {
        return getEditMode(entityType, panelTitleGetter);
    }
    return null;
}

export function getAllModes(registry: PanelRegistry): ModeCollection {
    const reac = registry === undefined ? new SuperRegistry().entityActions : registry.entityActions;

    const byEntity = {
        [Admin.classInternalName]: {
            list: getListModeFromRegistry(reac, Admin, 2, () => 'Admin List', 'Admins'),
            info: getInfoModeFromRegistry(reac, Admin, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, Admin, () => `New Admin`),
            edit: getEditModeFromRegistry(reac, Admin, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [AdminRT.classInternalName]: {
            list: getListModeFromRegistry(reac, AdminRT, 2, () => 'Admin RT List', 'Admin RTs'),
            info: getInfoModeFromRegistry(reac, AdminRT, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, AdminRT, () => `New Admin RT`),
            edit: getEditModeFromRegistry(reac, AdminRT, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [EmailCheck.classInternalName]: {
            list: getListModeFromRegistry(reac, EmailCheck, 2, () => 'Email Check List', 'Email Checks'),
            info: getInfoModeFromRegistry(reac, EmailCheck, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, EmailCheck, () => `New Email Check`),
            edit: getEditModeFromRegistry(reac, EmailCheck, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [Policy.classInternalName]: {
            list: getListModeFromRegistry(reac, Policy, 2, () => 'Policy List', 'Policies'),
            info: getInfoModeFromRegistry(reac, Policy, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, Policy, () => `New Policy`),
            edit: getEditModeFromRegistry(reac, Policy, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [User.classInternalName]: {
            list: getListModeFromRegistry(
                reac,
                User,
                1,
                () => 'User List',
                'Users',
                [{
                    component: <ComputeUserScoresButton />
                }],
            ),
            info: getInfoModeFromRegistry(reac, User, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, User, () => `New User`),
            edit: getEditModeFromRegistry(reac, User, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [RedditCheck.classInternalName]: {
            list: getListModeFromRegistry(reac, RedditCheck, 2, () => 'Reddit Check List', 'Reddit Checks'),
            info: getInfoModeFromRegistry(reac, RedditCheck, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, RedditCheck, () => `New Reddit Check`),
            edit: getEditModeFromRegistry(reac, RedditCheck, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [Institution.classInternalName]: {
            list: getListModeFromRegistry(reac, Institution, 1, () => 'Institution List', 'Institutions'),
            info: getInfoModeFromRegistry(reac, Institution, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, Institution, () => `New Institution`),
            edit: getEditModeFromRegistry(reac, Institution, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [RewardProgram.classInternalName]: {
            list: getListModeFromRegistry(reac, RewardProgram, 1, () => 'Reward Program List', 'Reward Programs'),
            info: getInfoModeFromRegistry(reac, RewardProgram, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, RewardProgram, () => `New Reward Program`),
            edit: getEditModeFromRegistry(reac, RewardProgram, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [CreditCard.classInternalName]: {
            list: getListModeFromRegistry(reac, CreditCard, 1, () => 'Card List', 'Credit Cards'),
            info: getInfoModeFromRegistry(reac, CreditCard, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, CreditCard, () => `New Card`),
            edit: getEditModeFromRegistry(reac, CreditCard, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [CreditCardOffer.classInternalName]: {
            list: getListModeFromRegistry(reac, CreditCardOffer, 1, () => 'CC Offer List', 'CC Offers'),
            info: getInfoModeFromRegistry(reac, CreditCardOffer, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, CreditCardOffer, () => `New CC Offer`),
            edit: getEditModeFromRegistry(reac, CreditCardOffer, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [CreditCardReferral.classInternalName]: {
            list: getListModeFromRegistry(reac, CreditCardReferral, 1, () => 'CC Referral List', 'CC Referrals'),
            info: getInfoModeFromRegistry(reac, CreditCardReferral, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, CreditCardReferral, () => `New CC Referral`),
            edit: getEditModeFromRegistry(reac, CreditCardReferral, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [InstitutionOffer.classInternalName]: {
            list: getListModeFromRegistry(reac, InstitutionOffer, 1, () => 'Institution Offer List', 'Institution Offers'),
            info: getInfoModeFromRegistry(reac, InstitutionOffer, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, InstitutionOffer, () => `New Institution Offer`),
            edit: getEditModeFromRegistry(reac, InstitutionOffer, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
        [InstitutionReferral.classInternalName]: {
            list: getListModeFromRegistry(reac, InstitutionReferral, 1, () => 'Institution Referral List', 'Institution Referrals'),
            info: getInfoModeFromRegistry(reac, InstitutionReferral, (entity: StoredEntity) => `${entity.displayName}`),
            create: getCreateModeFromRegistry(reac, InstitutionReferral, () => `New Institution Referral`),
            edit: getEditModeFromRegistry(reac, InstitutionReferral, (entity: StoredEntity) => `Edit ${entity.displayName}`),
        },
    };

    const modes = {};
    for (const [entityName, entityMode] of Object.entries(byEntity)) {
        for (const [modeName, mode] of Object.entries(entityMode)) {
            if (mode !== null) {
                modes[`${entityName}-${modeName}`] = mode;
            }
        }
    }

    return modes;
}
