// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import { createdAtField, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';
import {AscendingSortOrder, Geography} from "../types";

class Institution implements StoredEntity {
    static classInternalName = 'institution';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        name: {
            displayName: 'Name',
            recordName: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        geography: {
            displayName: 'Geography',
            recordName: 'geography',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: Geography,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        referralLinkRegex: {
            displayName: 'Referral Link Regex',
            recordName: 'referral_link_regex',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: false,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: false,
        },
        referralsEnabled: {
            displayName: 'Referrals Enabled',
            recordName: 'referrals_enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {
        logo: {
            displayName: 'Logo',
            fileType: 'img',
            fileName: `logo`,
            displayOnMain: true,
        },
    };

    static defaultSort: SortParams = {
        fieldName: 'name',
        field: Institution.fields.name,
        order: AscendingSortOrder,
    };

    get id() {
        return this.record[Institution.fields.id.recordName];
    }

    get displayName() {
        const countriesEnumMap = Institution.fields.geography.dataType.extraData;
        const geography = countriesEnumMap[this.record[Institution.fields.geography.recordName]];
        return `${this.record[Institution.fields.name.recordName]} ${geography}`;
    }

    static endpoints: Endpoints = constructEndpoints('institutions');

    constructor(record: any) {
        this.record = record;
    }
}

export default Institution;
