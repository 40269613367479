// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import Policy from './Policy';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';

class Admin implements StoredEntity {
    static classInternalName = 'admin';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        firstName: {
            displayName: 'First Name',
            recordName: 'first_name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        lastName: {
            displayName: 'Last Name',
            recordName: 'last_name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        email: {
            displayName: 'Email',
            recordName: 'email',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        isSuper: {
            displayName: 'Is Super',
            recordName: 'is_super',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        policyId: {
            displayName: 'Policy',
            recordName: 'policy_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    maxValues: 1,
                    model: Policy,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[Admin.fields.id.recordName];
    }

    get displayName() {
        return this.record[Admin.fields.firstName.recordName] || this.record[Admin.fields.email.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('admins');

    constructor(record: any) {
        this.record = record;
    }
}

export default Admin;
