// @flow

import type { AccessControlState, AccessControlAction } from './types';
import AuthToken from '../../entities/AuthToken';

function getInitialAccessControlState(): AccessControlState {
    return {
        loginStatus: null,
        registerStatus: null,
        emailSuggestion: '',
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceAccessControl(state: AccessControlState, action: AccessControlAction): AccessControlState {
    switch (action.type) {
        case 'accessControl/requestLoginAction':
            return { ...state, loginStatus: 'pending' };
        case 'accessControl/receiveToken':
            AuthToken.set(action.token);
            return { ...state, loginStatus: 'ok' };

        case 'accessControl/requestRegistration':
            return { ...state, registrationStatus: 'pending' };
        case 'accessControl/receiveRegistrationConfirmation':
            return { ...state, registrationStatus: 'ok', emailSuggestion: action.email };

        default:
            return getInitialAccessControlState();
    }
}
