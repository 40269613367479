// @flow

import React, { Component } from 'react';

import SiteNavbar from './SiteNavbar';

import ErrorWrapper from './errors/ErrorWrapper';
import type { ModeCollection } from '../modes';

const GenericPage = ({
    currentModeName,
    modes,
    component,
}: {
    currentModeName: string,
    modes: ModeCollection,
    component: Component,
}): Component => (
    <div>
        <SiteNavbar currentModeName={currentModeName} modes={modes} />
        <ErrorWrapper>{component}</ErrorWrapper>
    </div>
);

export default GenericPage;
