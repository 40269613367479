// @flow

import type { Endpoints, PaginationParams, QueryList, SortParams } from './types';
import { encodeQueryData } from '../store/requests';
import { getDefaultValueForField } from '../ui/helpers';
import { AscendingSortOrder } from './types';

function processQueryList(queryList: QueryList): string {
    const query = {};

    Object.values(queryList).forEach((queryRecord) => {
        // Ignore meaningless search queries
        // Ref requires special handling as empty array != empty array
        const isEmptyRef = queryRecord.field.dataType.primitive === 'ref' && queryRecord.value.length === 0;
        if (isEmptyRef || getDefaultValueForField(queryRecord.field) === queryRecord.value) {
            return;
        }

        const rName = queryRecord.field.recordName;

        switch (queryRecord.field.dataType.primitive) {
            case 'ts':
                // Transform the date from the HTML input into a timestamp
                query[rName] = {
                    value: new Date(queryRecord.value).getTime() / 1000,
                };
                break;
            case 'ref':
                // Map the objects to their IDs
                query[rName] = {
                    value: queryRecord.value.map((e) => e.id),
                };
                break;
            case 'num':
            case 'enum':
                // Make it a number from the HTML string input or the object string key
                query[rName] = {
                    value: parseInt(queryRecord.value, 10),
                };
                break;
            case 'bool':
                query[rName] = {
                    value: Boolean(parseInt(queryRecord.value, 10)),
                };
                break;
            default:
                query[rName] = {
                    value: queryRecord.value,
                };
        }

        query[rName].mode = queryRecord.mode;
    });

    return JSON.stringify(query);
}

function processSortParams(sortParams: SortParams) {
    return JSON.stringify({
        field: sortParams.field.recordName,
        order: sortParams.order === AscendingSortOrder ? 1 : -1,
    });
}

function processPaginationParams(paginationParams: PaginationParams) {
    return JSON.stringify({
        page: paginationParams.page,
        perPage: paginationParams.perPage,
    });
}

// eslint-disable-next-line import/prefer-default-export
export function constructEndpoints(root: string): Endpoints {
    return {
        list: (queryList: ?QueryList, sortParams: ?SortParams, paginationParams: ?PaginationParams) => {
            const args = {};

            if (queryList) {
                args.query = processQueryList(queryList);
            }

            // Ignore these for now
            if (sortParams) {
                args.sort = processSortParams(sortParams);
            }

            if (paginationParams) {
                args.pagination = processPaginationParams(paginationParams);
            }

            return `${root}/all?${encodeQueryData(args)}`;
        },
        suggestions: () => `${root}/all/suggestions`,
        count: (queryList: ?QueryList) => {
            const args = {};

            // Ignore this for now
            if (queryList) {
                args.query = processQueryList(queryList);
            }

            return `${root}/all/count?${encodeQueryData(args)}`;
        },
        info: (id: string) => `${root}/one/${id}`,
        file: (id: string, fileName: string) => `${root}/one/${id}/${fileName}`,
    };
}
