// @flow

import React from 'react';
import { connect } from 'react-redux';

import ErrorAlert from './ErrorAlert';

import type { ErrorsState } from '../../../store/errors/types';

type ErrorfullPropsType = {
    children: React.Children,
    errorMessage: ?string,
};

const ErrorWrapper = ({ children, errorMessage }: ErrorfullPropsType) => (
    <div>
        {errorMessage && <ErrorAlert errorString={errorMessage} />}
        {children}
    </div>
);

const mapStateToProps = ({ errors }: ErrorsState): { errorMessage: ?string } => ({ errorMessage: errors.errorMessage });
export default connect(mapStateToProps)(ErrorWrapper);
