// @flow

import type { AppInitializationStatus, ReceiveGovernor, ReceivePolicy } from './types';

import { jsonGetRequest } from '../requests';
import { setErrorAction } from '../errors/actions';
import Admin from '../../entities/models/Admin';
import Policy from '../../entities/models/Policy';

function receiveGovernor(governor: any): ReceiveGovernor {
    return {
        type: 'app/receiveGovernor',
        governor: new Admin(governor),
    };
}

export function fetchGovernor() {
    return (dispatch) => {
        jsonGetRequest('admins/me')
            .then((value) => dispatch(receiveGovernor(value)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

function receivePolicy(policy: any): ReceivePolicy {
    return {
        type: 'app/receivePolicy',
        policy: new Policy(policy),
    };
}

export function fetchPolicy(id: string) {
    return (dispatch) => {
        jsonGetRequest(Policy.endpoints.info(id))
            .then((value) => dispatch(receivePolicy(value)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

export function setInitializationStatus(is: AppInitializationStatus) {
    return (dispatch) => {
        dispatch({
            type: 'app/setInitializationStatus',
            is,
        });
    };
}
