// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import type {StoredEntity} from "../abstract/StoredEntity";
import {createdAtField, getCreatedAtSort, idField} from "../abstract/StoredEntity";
import CreditCard from "./CreditCard";
import RewardProgram from "./RewardProgram";
import {constructEndpoints} from "../helpers";
import {Geography} from "../types";

class CreditCardOffer implements StoredEntity {
    static classInternalName = 'cc_offer';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        ccId: {
            displayName: 'Credit Card',
            recordName: 'cc_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: CreditCard
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        rewardProgramId: {
            displayName: 'Reward Program',
            recordName: 'reward_program_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: RewardProgram,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        offerType: {
            displayName: 'Type',
            recordName: 'offer_type',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Referral',
                    2: 'Affiliate'
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        geography: {
            displayName: 'Geography',
            recordName: 'geography',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: Geography,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        description: {
            displayName: 'Description',
            recordName: 'description',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        msr: {
            displayName: 'MSR',
            recordName: 'msr',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        wb: {
            displayName: 'WB',
            recordName: 'wb',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        af: {
            displayName: 'AF',
            recordName: 'af',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        fyf: {
            displayName: 'FYF',
            recordName: 'fyf',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        netValue: {
            displayName: 'Net Value',
            recordName: 'net_value',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams =  getCreatedAtSort();

    get id() {
        return this.record[CreditCardOffer.fields.id.recordName];
    }

    get displayName() {
        return this.record[CreditCardOffer.fields.id.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('cc-offers');

    constructor(record: any) {
        this.record = record;
    }
}

export default CreditCardOffer;
