// @flow

import React, { Component } from 'react';
import { FormGroup, Label, ButtonGroup, Button } from 'reactstrap';
import type { ValueToDisplayNameMap } from '../../../entities/types';
import { getRandomString } from '../../utils';

type PropTypes = {
    id: string,
    displayName: string,
    data: ValueToDisplayNameMap,
    value: any,
    onChange: (string, number) => void,
    embedInFormGroup: boolean,
};

class SegmentedControl extends Component<PropTypes, {}> {
    static defaultProps = {
        displayName: '',
        embedInFormGroup: false,
    };

    onChange(value: any) {
        this.props.onChange(this.props.id, value);
    }

    render() {
        const buttons = [];

        Object.entries(this.props.data).forEach(([raw, display]) =>
            buttons.push(
                <Button key={getRandomString()} active={this.props.value === raw} onClick={() => this.onChange(raw)}>
                    {display}
                </Button>,
            ),
        );

        const buttonGroup = <ButtonGroup>{buttons}</ButtonGroup>;

        return this.props.embedInFormGroup ? (
            <FormGroup>
                <Label for={this.props.id}>{this.props.displayName}</Label>
                <br />
                {buttonGroup}
            </FormGroup>
        ) : (
            buttonGroup
        );
    }
}

export default SegmentedControl;
