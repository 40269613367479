// @flow

import Admin from '../entities/models/Admin';
import AdminRT from '../entities/models/AdminRT';
import type { Mode } from './modes';
import User from '../entities/models/User';
import PolicyEntityAction from '../entities/models/PolicyEntityAction';
import Policy from "../entities/models/Policy";
import EmailCheck from "../entities/models/EmailCheck";
import Institution from "../entities/models/Institution";
import CreditCard from "../entities/models/CreditCard";
import RewardProgram from "../entities/models/RewardProgram";
import RedditCheck from "../entities/models/RedditCheck";
import CreditCardOffer from "../entities/models/CreditCardOffer";
import InstitutionOffer from "../entities/models/InstitutionOffer";
import CreditCardReferral from "../entities/models/CreditCardReferral";
import InstitutionReferral from "../entities/models/InstitutionReferral";

//
//
// ENTITY ACTIONS
//
//

export type RegistryEntityAction = {
    list: Mode,
    info: Mode,
    create: Mode,
    edit: Mode,
    [key: string]: Mode,
};

export type RegistryEntityActionCollection = {
    [key: string]: RegistryEntityAction,
};

// Generate an allow-all (except nonexistent actions) registry record for all known entities
export function getAllRegistryEntityActions(): RegistryEntityActionCollection {
    return {
        [Admin.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: true,
                d: undefined,
            },
        },
        [AdminRT.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: undefined,
            },
        },
        [EmailCheck.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: undefined,
                d: true,
            },
        },
        [Policy.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: undefined,
            },
        },
        [User.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: true,
                d: true,
            },
        },
        [RedditCheck.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: undefined,
                d: true,
            },
        },
        [Institution.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: true,
            },
        },
        [RewardProgram.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: true,
            },
        },
        [CreditCard.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: true,
            },
        },
        [CreditCardOffer.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: true,
            },
        },
        [InstitutionOffer.classInternalName]: {
            operations: {
                c: true,
                r: true,
                u: true,
                d: true,
            },
        },
        [CreditCardReferral.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: true,
                d: true,
            },
        },
        [InstitutionReferral.classInternalName]: {
            operations: {
                c: undefined,
                r: true,
                u: true,
                d: true,
            },
        },
    };
}

// Generate a deny-all (except nonexistent actions) registry record for all known entities
export function getAllDeniedEntityActions(): RegistryEntityActionCollection {
    return {
        [Admin.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: false,
                d: undefined,
            },
        },
        [AdminRT.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: undefined,
            },
        },
        [EmailCheck.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: undefined,
                d: false,
            },
        },
        [Policy.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: undefined,
            },
        },
        [User.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: false,
                d: false,
            },
        },
        [RedditCheck.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: undefined,
                d: false,
            },
        },
        [Institution.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: false,
            },
        },
        [RewardProgram.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: false,
            },
        },
        [CreditCard.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: false,
            },
        },
        [CreditCardOffer.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: false,
            },
        },
        [InstitutionOffer.classInternalName]: {
            operations: {
                c: false,
                r: false,
                u: false,
                d: false,
            },
        },
        [CreditCardReferral.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: false,
                d: false,
            },
        },
        [InstitutionReferral.classInternalName]: {
            operations: {
                c: undefined,
                r: false,
                u: false,
                d: false,
            },
        },
    };
}

//
//
// PANEL REGISTRY
//
//

// A container for all entity and permission actions
export interface PanelRegistry {
    entityActions: RegistryEntityActionCollection;
}

// A registry which allows all actions currently available in the admin panel
export class SuperRegistry implements PanelRegistry {
    constructor() {
        this.entityActions = getAllRegistryEntityActions();
    }
}

// A registry which filters out actions available in a policy
export class PolicyBasedRegistry implements PanelRegistry {
    constructor(policy: ?Policy) {
        // Transform what's in the policy into registry records

        this.entityActions = getAllDeniedEntityActions();

        // Get the entity action records
        const peas = policy.record[Policy.fields.entityActions.recordName].map((r) => new PolicyEntityAction(r));

        peas.forEach((action) => {
            // Get the entity type for faster access
            const entityType = action.record[PolicyEntityAction.fields.entityType.recordName];

            // Just a safety check in case the backoffice
            // doesn't get updated in time with the backend entity types
            if (this.entityActions.hasOwnProperty(entityType)) {
                // Grab the registry counterpart of the policy action
                const rea = this.entityActions[entityType];

                // Project the operations
                const peaOperations = action.record[PolicyEntityAction.fields.operations.recordName];

                rea.operations.c = rea.operations.c === undefined ? undefined : peaOperations.includes(1);
                rea.operations.r = rea.operations.r === undefined ? undefined : peaOperations.includes(2);
                rea.operations.u = rea.operations.u === undefined ? undefined : peaOperations.includes(3);
                rea.operations.d = rea.operations.d === undefined ? undefined : peaOperations.includes(4);
            }
        });
    }
}
