// @flow

import type {Endpoints, EntityFieldCollection, SortParams} from '../types';
import {createdAtField, getCreatedAtSort, idField} from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';
import User from "./User";
import UrlClick from "./UrlClick";
import InstitutionOffer from "./InstitutionOffer";

class InstitutionReferral implements StoredEntity {
    static classInternalName = 'institution_referral';

    static fields: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        offerId: {
            displayName: 'Offer',
            recordName: 'offer_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: InstitutionOffer
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        userId: {
            displayName: 'User',
            recordName: 'user_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: User
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        link: {
            displayName: 'Link',
            recordName: 'link',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            recordName: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        clicks: {
            displayName: 'Clicks',
            recordName: 'clicks',
            dataType: {
                primitive: 'simple-entity',
                isArray: true,
                extraData: {
                    model: UrlClick
                }
            },
            supportsQuery: false,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: false,
        },
        expiration: {
            displayName: 'Expiration',
            recordName: 'expiration',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.record[InstitutionReferral.fields.id.recordName];
    }

    get displayName() {
        return this.record[InstitutionReferral.fields.id.recordName];
    }

    static endpoints: Endpoints = constructEndpoints('institution-referrals');

    constructor(record: any) {
        this.record = record;
    }
}

export default InstitutionReferral;
