// @flow

import type { RequestLoginAction, ReceiveTokenAction } from './types';
import { setErrorAction } from '../errors/actions';
import { processFetchErrors, constructBasicAuthHeader } from '../requests';
import { loginUrl } from './endpoints';

function requestLogin(): RequestLoginAction {
    return {
        type: 'accessControl/requestLogin',
    };
}

function receiveToken(json): ReceiveTokenAction {
    return {
        type: 'accessControl/receiveToken',
        token: json.token,
    };
}

export function login(data) {
    return (dispatch) => {
        dispatch(requestLogin());

        const authHeader = constructBasicAuthHeader(data.email, data.password);

        processFetchErrors(
            fetch(loginUrl, {
                method: 'GET',
                headers: authHeader,
            }),
        )
            .then((json) => {
                dispatch(receiveToken(json));
                window.location.replace(window.location.pathname);
            })
            .then((json) => dispatch(receiveToken(json)))
            .catch((error) => dispatch(setErrorAction(error.toString())));
    };
}

export function register() {
    return null;
}
