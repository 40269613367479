// @flow

import type { ErrorsState, ErrorsAction } from './types';

const initialState: ErrorsState = {
    errorMessage: null,
    errorTimer: null,
};

// eslint-disable-next-line import/prefer-default-export
export function reduce(
    state: ErrorsState = initialState,
    action: ErrorsAction = { type: 'errors/clear' },
): ErrorsState {
    switch (action.type) {
        case 'errors/clear':
            if (state.errorTimer) {
                clearTimeout(state.errorTimer);
            }
            return initialState;

        case 'errors/set': {
            if (state.errorTimer) {
                clearTimeout(state.errorTimer);
            }

            return { ...state, ...action };
        }

        default:
            return state;
    }
}
